import { createSelector } from 'reselect';

const getData = (state) => state.products.data;
const getCurCategoryId = (state) => state.products.getCurCategoryId;

export const getProductsByCategoryId = createSelector(
  getData, getCurCategoryId,
  (data, id) => {
    if (id != 0) {
        var products = data.filter(function( p ) {
            return p.CategoryId == id;
        });

        return products;
    } else {
        return data;
    }
  }
);
import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';
import config from "../config";

export const RECEIVED_USERS = 'RECEIVED_USERS';
export const RECEIVING_USERS = 'RECEIVING_USERS';
export const RECEIVED_USER = 'RECEIVED_USER';
export const RECEIVING_USER = 'RECEIVING_USER';
export const UPDATED_USER = 'UPDATED_USER';
export const CREATED_USER = 'CREATED_USER';
export const UPDATING_USER = 'UPDATING_USER';
export const DELETED_USER = 'DELETED_USER';
export const DELETING_USER = 'DELETING_USER';
export const FAILURE_USER = 'FAILURE_USER';
export const SET_ROLE_ID = 'SET_ROLE_ID';

export function setRoleId(id) {
    return (dispatch) => {
        dispatch({
            type: SET_ROLE_ID,
            payload: id
        });
    };
}

async function getRoles(merchantId) {
    const response = await axios.get(`/api/user/roles/${merchantId}`);
    return response.data.roles;
}

export function getUsersRequest(merchantId) {
    return async (dispatch) => {
        dispatch(receivingUsers());

        let roles = await getRoles(merchantId);
        
        axios.get(`/api/useradmin/users`).then(res => {
            let users = res.data.users;
            let payload = {
                roles,
                users
            };
            dispatch(receiveUsers(payload));
        })
    };
}

export function loadUserRequest(id) {
    return (dispatch) => {
        dispatch(receivingUser());
        axios.get('/api/useradmin/' + id).then(res => {
            console.log("user");
            console.log(res);
            //dispatch(receiveUser(res.data));
        })
    };
}

export function updateUserRequest(user) {
    return (dispatch) => {
        dispatch(updatingUser());
        axios.put('/api/useradmin/update', user).then(res => {
            if (res.data.status == "Error") {
                toast.error(res.data.message);
                dispatch(failtureUser(res.data.message));
            } else {
                dispatch(updateUser(user));
                toast.success("User has been Updated!");
            }
        })
    };
}

export function createUserRequest(user) {
    return (dispatch) => {
        dispatch(updatingUser());
        axios.post('/api/useradmin/add', user).then(res => {
            if (res.data.status == "Error") {
                toast.error(res.data.message);
                dispatch(failtureUser(res.data.message));
            } else {
                dispatch(createUser(res.data.user));
                toast.success("User has been Created!");
            }
        })
    };
}

export function deleteUserRequest(id) {
    return (dispatch) => {
        dispatch(deletingUser(id));
        axios.delete('/api/useradmin/delete/' + id).then(res => {
            if (res.data.status == "Error") {
                toast.error(res.data.message);
                dispatch(failtureUser(res.data.message));
            } else {
                dispatch(deleteUser(id));
                toast.success("User has been Deleted!");
            }
        })
    };
}

export function receiveUsers(payload) {
    console.log(payload);
    return {
        type: RECEIVED_USERS,
        payload
    }
}

export function receivingUsers() {
    return {
        type: RECEIVING_USERS
    }
}

export function receiveUser(payload) {
    return {
        type: RECEIVED_USER,
        payload
    }
}

export function receivingUser() {
    return {
        type: RECEIVING_USER
    }
}

export function createUser(payload) {
    return {
        type: CREATED_USER,
        payload
    }
}

export function updateUser(payload) {
    return {
        type: UPDATED_USER,
        payload
    }
}

export function updatingUser() {
    return {
        type: UPDATING_USER
    }
}

export function deleteUser(payload) {
    return {
        type: DELETED_USER,
        payload
    }
}

export function deletingUser(payload) {
    return {
        type: DELETING_USER,
        payload
    }
}

export function failtureUser(payload) {
    return {
        type: FAILURE_USER,
        payload
    }
}

import { combineReducers } from 'redux';
import auth from './auth';
import products from './products';
import settings from './settings';
import users from './users';
import navigation from './navigation';
import { connectRouter } from 'connected-react-router';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    navigation,
    products,
    settings,
    users
});

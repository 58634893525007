export const mockCategory = {
  CategoryId: 0,
  Name: "",
  PrinterId: 1,
  Picture: ""
}

export const mockPrinters = [
  {
    Id: 1,
    Name: "Kitchen"
  },
  {
    Id: 2,
    Name: "Bar"
  }
]
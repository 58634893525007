import { 
  CHANGE_ACTIVE_NAVBAR_ITEM, 
} from '../actions/navigation';

const initialState = {
  activeItem: 'products'
};

export default function runtime(state = initialState, action) {
  switch (action.type) {
    case CHANGE_ACTIVE_NAVBAR_ITEM:
      return {
        ...state,
        activeItem: action.activeItem,
      };
    default:
      return state;
  }
}


import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { HashRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ConnectedRouter } from 'connected-react-router';
import { getHistory } from '../index';
import { AdminRoute, UserRoute, AuthRoute } from './RouteComponents';

import '../styles/theme.scss';

import Login from '../pages/auth/Login';
import LayoutComponent from '../components/Layout';
// import Products from '../pages/products/Products';

const CloseButton = ({closeToast}) => <i onClick={closeToast} className="la la-close notifications-close"/>

class App extends React.PureComponent {
  
  render() {
    if (this.props.loadingInit) {
      return <div/>;
    }

    return (
        <div>
            <ToastContainer
                autoClose={5000}
                hideProgressBar = {true}
                closeButton={<CloseButton/>}
            />
            <ConnectedRouter history={getHistory()}>
              <HashRouter>
                  <Switch>
                      <Route path="/" exact render={() => <Redirect to="/admin/products"/>}/>
                      <Route path="/admin" exact render={() => <Redirect to="/admin/products"/>}/>
                      <AuthRoute path="/login" exact component={Login}/>
                      <UserRoute path="/admin" dispatch={this.props.dispatch} component={LayoutComponent}/>
                      <Redirect from="*" to="/admin/products"/>
                      {/* <Route path="/products" exact component={Products}/> */}
                  </Switch>
              </HashRouter>
            </ConnectedRouter>
        </div>

    );
  }
}

const mapStateToProps = store => ({
  currentUser: store.auth.currentUser,
  loadingInit: store.auth.loadingInit,
});

export default connect(mapStateToProps)(App);

import axios from 'axios';
import { toast } from 'react-toastify';
import mock from "../pages/products/backendMock";
import config from "../config";

import { receiveCategories } from './products';

export const RECEIVING_STATIONS = 'RECEIVING_STATIONS';
export const RECEIVED_STATIONS = 'RECEIVED_STATIONS';
export const FAILURE_STATIONS = 'FAILURE_STATIONS';
export const UPDATING_STATIONS = 'UPDATING_STATIONS';

export const RECEIVING_PICTURES = 'RECEIVING_PICTURES';
export const RECEIVED_PICTURES = 'RECEIVED_PICTURES';
export const FAILURE_PICTURES = 'FAILURE_PICTURES';
export const UPDATING_PICTURES = 'UPDATING_PICTURES';
export const DELETING_PICTURES = 'DELETING_PICTURES';

export const RECEIVING_VIDEOS = 'RECEIVING_VIDEOS';
export const RECEIVED_VIDEOS = 'RECEIVED_VIDEOS';
export const FAILURE_VIDEOS = 'FAILURE_VIDEOS';
export const UPDATING_VIDEOS = 'UPDATING_VIDEOS';
export const DELETING_VIDEOS = 'DELETING_VIDEOS';

export const RECEIVING_WEBSITES = 'RECEIVING_WEBSITES';
export const RECEIVED_WEBSITES = 'RECEIVED_WEBSITES';
export const FAILURE_WEBSITES = 'FAILURE_WEBSITES';
export const UPDATING_WEBSITES = 'UPDATING_WEBSITES';
export const DELETING_WEBSITES = 'DELETING_WEBSITES';

export const RECEIVING_STATION_SETTINGS = 'RECEIVING_STATION_SETTINGS';
export const RECEIVED_STATION_SETTINGS = 'RECEIVED_STATION_SETTINGS';
export const FAILURE_STATION_SETTINGS = 'FAILURE_STATION_SETTINGS';

export const RECEIVING_STORE_DETAILS = 'RECEIVING_STORE_DETAILS';
export const UPDATING_STORE_DETAILS = 'UPDATING_STORE_DETAILS';
export const RECEIVED_STORE_DETAILS = 'RECEIVED_STORE_DETAILS';
export const FAILURE_STORE_DETAILS = 'FAILURE_STORE_DETAILS';

export const UPDATING_PRODUCT_DEFAULTS = 'UPDATING_PRODUCT_DEFAULTS';
export const RECEIVED_PRODUCT_DEFAULTS = 'RECEIVED_PRODUCT_DEFAULTS';
export const FAILURE_PRODUCT_DEFAULTS = 'FAILURE_PRODUCT_DEFAULTS';

async function getAvailableStations(merchantId) {
    const response = await axios.get(`/api/station/list/${merchantId}`);
    return response.data;
}

async function getStationById(stationId) {
    const response = await axios.get(`/api/station/${stationId}`);
    return response.data;
}

export function loadAvailableStations(merchantId) {
    return async (dispatch) => {
        dispatch(receivingStations());
        
        const data = await getAvailableStations(merchantId)
        if (data.status == 'Ok') {
            const payload = {
                stations: data.stations
            }
            dispatch(receivedStations(payload));
        } else {
            dispatch(failtureStations(data.message));
        }
    };
}

export function createStation(payload) {
    return async (dispatch) => {
        console.log(payload);

        dispatch(updatingStations());

        axios.post('/api/station/add', payload).then(async res => {
            console.log(res);
            if (res.data.status == "Error") {
                toast.error(res.data.message);
                dispatch(failtureStations(res.data.message));
            } else {
                const data = await getAvailableStations(payload.MerchantId);
                if (data.status == 'Ok') {
                    const station_payload = {
                        stations: data.stations
                    }
                    dispatch(receivedStations(station_payload));
                } else {
                    toast.error(data.message);
                    dispatch(failtureStations(data.message));
                }
                toast.success("A station has been created!");
            }
        })
    };
}

export function updateStation(payload) {
    return async (dispatch) => {
        dispatch(updatingStations());
        var stationId =  payload.StationId;

        var data = await getStationById(stationId)
        if (data.status == 'Ok') {
            var station = data.station;
            station.Name = payload.Name;

            axios.put('/api/station/update', station).then(async res => {
                if (res.data.status == "Error") {
                    toast.error(res.data.message);
                    dispatch(failtureStations(res.data.message));
                } else {
                    const data = await getAvailableStations(station.MerchantId);
                    if (data.status == 'Ok') {
                        const station_payload = {
                            stations: data.stations
                        }
                        dispatch(receivedStations(station_payload));
                    } else {
                        toast.error(data.message);
                        dispatch(failtureStations(data.message));
                    }
                    
                    toast.success("The station has been updated!");
                }
            })
        } else {
            dispatch(failtureStations(data.message));
        }
    };
}

async function getStorePictures() {
    const response = await axios.get(`/api/assets/store/pictures`);
    return response.data;
}

async function getAssetById(assetId) {
    const response = await axios.get(`/api/assets/${assetId}`);
    return response.data;
}

export function loadPictures() {
    return async (dispatch) => {
        dispatch(receivingPictures());
        
        const data = await getStorePictures()
        if (data.status == 'Ok') {
            const payload = {
                pictures: data.pictures
            }
            dispatch(receivedPictures(payload));
        } else {
            toast.error(data.message);
            dispatch(failturePictures(data.message));
        }
    };
}

export function createPicture(payload) {
    return async (dispatch) => {
        
        dispatch(updatingPictures());

        const formData = new FormData();
        formData.append('file',payload.picFile)
        formData.append('assetName',payload.picName)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const res = await axios.post("/assets/picture/upload", formData,config)
        if (res.data.status == "Error") {
            toast.error(res.data.message);
            dispatch(failturePictures(res.data.message));
        } else {
            const data = await getStorePictures();
            if (data.status == 'Ok') {
                const payload = {
                    pictures: data.pictures
                }
                dispatch(receivedPictures(payload));
            } else {
                toast.error(data.message);
                dispatch(failturePictures(data.message));
            }
            toast.success("A Picture has been uploaded!");
        }
    }
}

async function updateAssetName(assetId, assetName) {
    const response = await axios.put(`/api/assets/${assetId}/rename/${assetName}`);
    return response.data;
}

export function updatePicture(payload) {
    return async (dispatch) => {
        dispatch(updatingPictures());
        var assetId =  payload.AssetId;
        var assetName =  payload.Name;

        var res = await updateAssetName(assetId, assetName)
        if (res.status == 'Ok') {
            const data = await getStorePictures();
            if (data.status == 'Ok') {
                const payload = {
                    pictures: data.pictures
                }
                dispatch(receivedPictures(payload));
            } else {
                toast.error(data.message);
                dispatch(failturePictures(data.message));
            }
            toast.success("The name of the picture has been updated!");
        } else {
            dispatch(failturePictures(res.message));
        }
    };
}

async function deleteAsset(assetId) {
    const response = await axios.delete(`/api/assets/${assetId}/delete/force`);
    return response.data;
}

export function deletePicture(id) {
    return async (dispatch) => {
        
        dispatch(deletingPictures());
        var res = await deleteAsset(id)
        if (res.status == 'Ok') {
            const data = await getStorePictures();
            if (data.status == 'Ok') {
                const payload = {
                    pictures: data.pictures
                }
                dispatch(receivedPictures(payload));
            } else {
                toast.error(data.message);
                dispatch(failturePictures(data.message));
            }
            toast.success("The picture has been deleted!");
        } else {
            dispatch(failturePictures(res.message));
        }
    };
}

async function getStoreVideos() {
    const response = await axios.get(`/api/assets/store/video`);
    return response.data;
}

export function loadVideos() {
    return async (dispatch) => {
        dispatch(receivingVideos());
        
        const data = await getStoreVideos()
        console.log("store video data")
        console.log(data)
        if (data.status == 'Ok') {
            const payload = {
                videos: data.video
            }
            dispatch(receivedVideos(payload));
        } else {
            toast.error(data.message);
            dispatch(failtureVideos(data.message));
        }
    };
}

export function createVideo(payload) {
    return async (dispatch) => {
        
        dispatch(updatingVideos());

        const formData = new FormData();
        formData.append('videoFile',payload.videoFile)
        formData.append('posterFile',payload.posterFile)
        formData.append('assetName',payload.videoName)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const res = await axios.post("/assets/video/upload", formData,config)
        if (res.data.status == "Error") {
            toast.error(res.data.message);
            dispatch(failtureVideos(res.data.message));
        } else {
            const data = await getStoreVideos();
            if (data.status == 'Ok') {
                const payload = {
                    videos: data.video
                }
                dispatch(receivedVideos(payload));
            } else {
                toast.error(data.message);
                dispatch(failtureVideos(data.message));
            }
            toast.success("A video has been uploaded!");
        }
    }
}

export function updateVideo(payload) {
    return async (dispatch) => {
        dispatch(updatingVideos());
        var assetId =  payload.AssetId;
        var assetName =  payload.Name;

        var res = await updateAssetName(assetId, assetName)
        if (res.status == 'Ok') {
            const data = await getStoreVideos();
            if (data.status == 'Ok') {
                const payload = {
                    videos: data.video
                }
                dispatch(receivedVideos(payload));
            } else {
                toast.error(data.message);
                dispatch(failtureVideos(data.message));
            }
            toast.success("The name of the video has been updated!");
        } else {
            dispatch(failtureVideos(res.message));
        }
    };
}

export function deleteVideo(id) {
    return async (dispatch) => {
        
        dispatch(deletingVideos());
        var res = await deleteAsset(id)
        if (res.status == 'Ok') {
            const data = await getStoreVideos();
            if (data.status == 'Ok') {
                const payload = {
                    videos: data.video
                }
                dispatch(receivedVideos(payload));
            } else {
                toast.error(data.message);
                dispatch(failtureVideos(data.message));
            }
            toast.success("The video has been deleted!");
        } else {
            dispatch(failtureVideos(res.message));
        }
    };
}

async function getStoreWebsites() {
    const response = await axios.get(`/api/assets/store/websites`);
    return response.data;
}

export function loadWebsites() {
    return async (dispatch) => {
        dispatch(receivingWebsites());
        
        const data = await getStoreWebsites()
        console.log("store website data")
        console.log(data)
        if (data.status == 'Ok') {
            const payload = {
                websites: data.websites
            }
            dispatch(receivedWebsites(payload));
        } else {
            toast.error(data.message);
            dispatch(failtureWebsites(data.message));
        }
    };
}

export function createWebsite(payload) {
    return async (dispatch) => {
        
        dispatch(updatingWebsites());

        console.log("create website payload")
        console.log(payload)

        const res = await axios.post("api/assets/store/website/add", payload)
        if (res.data.status == "Error") {
            toast.error(res.data.message);
            dispatch(failtureWebsites(res.data.message));
        } else {
            const data = await getStoreWebsites();
            if (data.status == 'Ok') {
                const payload = {
                    websites: data.websites
                }
                dispatch(receivedWebsites(payload));
            } else {
                toast.error(data.message);
                dispatch(failtureWebsites(data.message));
            }
            toast.success("A website has been added!");
        }
    }
}

export function updateWebsite(payload) {
    return async (dispatch) => {
        dispatch(updatingWebsites());
        
        var res = await axios.put('/api/assets/store/website/update', payload);
        if (res.data.status == 'Ok') {
            const data = await getStoreWebsites();
            if (data.status == 'Ok') {
                const payload = {
                    websites: data.websites
                }
                dispatch(receivedWebsites(payload));
            } else {
                toast.error(data.message);
                dispatch(failtureWebsites(data.message));
            }
            toast.success("The website has been updated!");
        } else {
            dispatch(failtureWebsites(res.data.message));
        }
    };
}

export function deleteWebsite(id) {
    return async (dispatch) => {
        
        dispatch(deletingWebsites());
        var res = await deleteAsset(id)
        if (res.status == 'Ok') {
            const data = await getStoreWebsites();
            if (data.status == 'Ok') {
                const payload = {
                    websites: data.websites
                }
                dispatch(receivedWebsites(payload));
            } else {
                toast.error(data.message);
                dispatch(failtureWebsites(data.message));
            }
            toast.success("The website has been deleted!");
        } else {
            dispatch(failtureWebsites(res.message));
        }
    };
}

export function loadStationSettings() {
    return async (dispatch) => {
        dispatch(receivingStationSettings());
        
        var data = await getStoreWebsites()
        console.log("store website data")
        console.log(data)
        if (data.status == 'Ok') {
            var websites = data.websites;
            var videos = [];
            var pictures = [];
            data = await getStoreVideos();
            console.log("store video data")
            console.log(data)
            if (data.status == 'Ok') {
                videos = data.video;

                data = await getStorePictures();
                console.log("store picture data")
                console.log(data)
                if (data.status == 'Ok') {
                    pictures = data.pictures;
                } else {
                    toast.error(data.message);
                    dispatch(failtureStationSettings(data.message));
                }
            } else {
                toast.error(data.message);
                dispatch(failtureStationSettings(data.message));
            }

            const payload = {
                websites,
                videos,
                pictures
            }

            dispatch(receivedStationSettings(payload));

        } else {
            toast.error(data.message);
            dispatch(failtureStationSettings(data.message));
        }
    };
}

async function getCategories(merchantId) {
    const response = await axios.get(`/api/products/categories/${merchantId}`);
    return response.data.categories;
}

async function getProductDefaults(merchantId) {
    const response = await axios.get(`/api/product/defaults/merchant/${merchantId}`);
    return response.data;
}

async function getStoreDetails() {
    const response = await axios.get(`/api/store/detail`);
    return response.data;
}

export function loadStoreDetails() {
    return async (dispatch) => {
        dispatch(receivingStoreDetails());
        
        const data = await getStoreDetails();
        if (data.status == 'Ok') {
            const payload = {
                storeDetails: data.storeDetails,
                states: data.states
            }

            const categories = await getCategories(data.storeDetails.MerchantId);

            let category_payload = {
                categories
            }
            dispatch(receiveCategories(category_payload));

            const prodDefaults = await getProductDefaults(data.storeDetails.MerchantId);
            if (prodDefaults.status == 'Ok') {
                let prod_payload = {
                    productDefaults: prodDefaults.defaults
                };
                dispatch(receivedProductDefaults(prod_payload));
            }

            dispatch(receivedStoreDetails(payload));
        } else {
            toast.error(data.message);
            dispatch(failtureStoreDetails(data.message));
        }
    };
}

export function updateStoreDetails(payload) {
    return async (dispatch) => {
        dispatch(updatingStoreDetails());

        console.log(payload)
        
        var res = await axios.post('/api/store/update', payload);
        if (res.data.status == 'Ok') {
            const data = await getStoreDetails()
            if (data.status == 'Ok') {
                const payload = {
                    storeDetails: data.storeDetails,
                    states: data.states
                }
                dispatch(receivedStoreDetails(payload));
            } else {
                toast.error(data.message);
                dispatch(failtureStoreDetails(data.message));
            }
        } else {
            dispatch(failtureStoreDetails(res.data.message));
        }
    };
}

async function uploadStorePicture(picFile) {
    const formData = new FormData();
    formData.append('file',picFile)
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await axios.post("/store/picture/upload", formData,config)

    return response;
}

export function updateStorePicture(payload) {
    return async (dispatch) => {
        dispatch(updatingStoreDetails());
        
        var res = await uploadStorePicture(payload);
        if (res.data.status == 'Ok') {
            const data = await getStoreDetails()
            console.log("upload store image data");
            console.log(data);
            if (data.status == 'Ok') {
                const payload = {
                    storeDetails: data.storeDetails,
                    states: data.states
                }
                dispatch(receivedStoreDetails(payload));
            } else {
                toast.error(data.message);
                dispatch(failtureStoreDetails(data.message));
            }
        } else {
            dispatch(failtureStoreDetails(res.data.message));
        }
    };
}

export function createProductDefaults(payload) {
    return async (dispatch) => {
        
        dispatch(updatingProductDefaults());

        const res = await axios.post("api/product/defaults", payload)
        if (res.data.status == "Error") {
            toast.error(res.data.message);
            dispatch(failtureProductDefaults(res.data.message));
        } else {
            const data = await getProductDefaults(payload.MerchantId);
            if (data.status == 'Ok') {
                const prod_payload = {
                    productDefaults: data.defaults
                }
                dispatch(receivedProductDefaults(prod_payload));
            } else {
                toast.error(data.message);
                dispatch(failtureProductDefaults(data.message));
            }
            toast.success("Product defaults has been added!");
        }
    }
}

export function updateProductDefaults(payload) {
    return async (dispatch) => {
        
        dispatch(updatingProductDefaults());

        const res = await axios.put("api/product/defaults/update", payload)
        if (res.data.status == "Error") {
            toast.error(res.data.message);
            dispatch(failtureProductDefaults(res.data.message));
        } else {
            const data = await getProductDefaults(payload.MerchantId);
            if (data.status == 'Ok') {
                const prod_payload = {
                    productDefaults: data.defaults
                }
                dispatch(receivedProductDefaults(prod_payload));
            } else {
                toast.error(data.message);
                dispatch(failtureProductDefaults(data.message));
            }
            toast.success("Product defaults has been updated!");
        }
    }
}

export function receivingStations() {
    return {
        type: RECEIVING_STATIONS
    }
}

export function updatingStations() {
    return {
        type: UPDATING_STATIONS
    }
}

export function receivedStations(payload) {
    return {
        type: RECEIVED_STATIONS,
        payload
    }
}

export function failtureStations(payload) {
    return {
        type: FAILURE_STATIONS,
        payload
    }
}

export function receivingPictures() {
    return {
        type: RECEIVING_PICTURES
    }
}

export function updatingPictures() {
    return {
        type: UPDATING_PICTURES
    }
}

export function deletingPictures() {
    return {
        type: DELETING_PICTURES
    }
}

export function receivedPictures(payload) {
    return {
        type: RECEIVED_PICTURES,
        payload
    }
}

export function failturePictures(payload) {
    return {
        type: FAILURE_PICTURES,
        payload
    }
}

export function receivingVideos() {
    return {
        type: RECEIVING_VIDEOS
    }
}

export function updatingVideos() {
    return {
        type: UPDATING_VIDEOS
    }
}

export function deletingVideos() {
    return {
        type: DELETING_VIDEOS
    }
}

export function receivedVideos(payload) {
    return {
        type: RECEIVED_VIDEOS,
        payload
    }
}

export function failtureVideos(payload) {
    return {
        type: FAILURE_VIDEOS,
        payload
    }
}

export function receivingWebsites() {
    return {
        type: RECEIVING_WEBSITES
    }
}

export function updatingWebsites() {
    return {
        type: UPDATING_WEBSITES
    }
}

export function deletingWebsites() {
    return {
        type: DELETING_WEBSITES
    }
}

export function receivedWebsites(payload) {
    return {
        type: RECEIVED_WEBSITES,
        payload
    }
}

export function failtureWebsites(payload) {
    return {
        type: FAILURE_WEBSITES,
        payload
    }
}

export function receivingStationSettings() {
    return {
        type: RECEIVING_STATION_SETTINGS
    }
}

export function receivedStationSettings(payload) {
    return {
        type: RECEIVED_STATION_SETTINGS,
        payload
    }
}

export function failtureStationSettings(payload) {
    return {
        type: FAILURE_STATION_SETTINGS,
        payload
    }
}

export function receivingStoreDetails() {
    return {
        type: RECEIVING_STORE_DETAILS
    }
}

export function updatingStoreDetails() {
    return {
        type: UPDATING_STORE_DETAILS
    }
}

export function receivedStoreDetails(payload) {
    return {
        type: RECEIVED_STORE_DETAILS,
        payload
    }
}

export function failtureStoreDetails(payload) {
    return {
        type: FAILURE_STORE_DETAILS,
        payload
    }
}

export function updatingProductDefaults() {
    return {
        type: UPDATING_PRODUCT_DEFAULTS
    }
}

export function receivedProductDefaults(payload) {
    return {
        type: RECEIVED_PRODUCT_DEFAULTS,
        payload
    }
}

export function failtureProductDefaults(payload) {
    return {
        type: FAILURE_PRODUCT_DEFAULTS,
        payload
    }
}
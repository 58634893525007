import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Alert } from 'reactstrap';

import { createProductRequest, updateProductRequest, getProductsRequest, createVariationRequest, updateVariationRequest, deleteVariationRequest, 
    createOptionRequest, updateOptionRequest, deleteOptionRequest, updateInventoryRequest } from '../../../actions/products'

import { mockProduct, mockVariation, mockOption, mockFeeOptions } from '../../../model/mockProduct';
import s from './ProductEdit.module.scss';

function WarningBanner() {
    
    return (
      <div >
        Warning!
      </div>
    );
 }

class ProductEdit extends React.Component {
    
    static propTypes = {
        products: PropTypes.array,
        dispatch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        products: []
    };

    state = {
        // curProduct: {
        //     ProductId: 0,
        //     CategoryId: 2,
        //     Name: "",
        //     Description: "",
        //     SKU: "",
        //     Barcode: "",
        //     Price: 0,
        //     FeeOption: 1,
        //     TaxRate: 0,
        //     Picture: null,
        //     Variations: [],
        //     Options:[]
        // },
        curProduct: {...mockProduct},
        prodError: "",
        variationError: "",
        optionError: "",
        inventoryError: "",
        picFile: null,
        tempPicture: "",
        isVariations: false,
        isOptions: false,
        isInventory: false
    };

    constructor() {
        super();

        this.changeName = this.changeName.bind(this);
        this.changePrice = this.changePrice.bind(this);
        this.changeSku = this.changeSku.bind(this);
        this.changeTax = this.changeTax.bind(this);
        this.changeDescription = this.changeDescription.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
        this.changeFee = this.changeFee.bind(this);
        this.changePicture = this.changePicture.bind(this);
        this.changeStock = this.changeStock.bind(this);
        this.changeVariations = this.changeVariations.bind(this);
        this.changeOptions = this.changeOptions.bind(this);
        this.changeInventory = this.changeInventory.bind(this);
    }

    componentDidMount() {
        if (this.props.curProductId != 0) {
            this.setCurProduct(this.props.curProductId);
        }/* else if (this.props.categories.length > 0) {
            console.log("mount category")
            var curProduct = this.state.curProduct;
            curProduct.CategoryId = this.props.categories[0].CategoryId;
            this.setState({curProduct: curProduct});
        } */
        else {
            const {productDefaults} = this.props;

            const prod = {...mockProduct};
            prod.Name = productDefaults.CustomName;
            prod.CategoryId = productDefaults.Category;
            prod.FeeOption = productDefaults.FeeOption;
            prod.TaxRate = productDefaults.TaxRate;
            
            this.setState({curProduct: prod});
        }

        if (this.props.products.length < 1) {
            this.props.dispatch(getProductsRequest(this.props.merchant.MerchantId));
        } 
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        const {curProductId, productDefaults} = this.props;
        if (productDefaults !== prevProps.productDefaults) {
            if (curProductId == 0) {
                const prod = {...mockProduct};
                prod.Name = productDefaults.CustomName;
                prod.CategoryId = productDefaults.Category;
                prod.FeeOption = productDefaults.FeeOption;
                prod.TaxRate = productDefaults.TaxRate;
                
                this.setState({curProduct: prod});
            }
        }
       /* if (this.props.isUpdating !== prevProps.isUpdating) {
            this.setCurProduct(this.props.curProductId);
        } else if (this.props.curProductId !== prevProps.curProductId) {
            this.setCurProduct(this.props.curProductId);
        } else if (this.props.categories !== prevProps.categories && this.props.categories.length > 0) {
            console.log("cateogory id");
            var curProduct = this.state.curProduct;
            curProduct.CategoryId = this.props.categories[0].CategoryId;
            this.setState({curProduct: curProduct});
        }*/
    }

    // static getDerivedStateFromProps(props, state) {
    //     if (props.curProductId !== state.prevPropsUserID) {
    //         return {
    //             prevPropsUserID: props.userID,
    //             email: props.defaultEmail
    //         };
    //     }

    //     console.log("derived");
    //     return null;
    // }

    setCurProduct = (productId) => {
        const product = this.getProductById(productId);

        const isVariations = product.Variations.length > 0 ? true : false;
        const isOptions = product.Options.length > 0 ? true : false;
        const isInventory = product.Inventory != 0 ? true : false;
        this.setState({curProduct: product, isVariations: isVariations, isOptions: isOptions, isInventory: isInventory});
    }

    changeName(event) {
        var curProduct = this.state.curProduct;
        curProduct.Name = event.target.value;
        this.setState({ curProduct: curProduct });
    }

    changePrice(event) {
        var curProduct = this.state.curProduct;
        curProduct.Price = event.target.value;
        this.setState({ curProduct: curProduct });
    }

    changeSku(event) {
        var curProduct = this.state.curProduct;
        curProduct.SKU = event.target.value;
        this.setState({ curProduct: curProduct });
    }

    changeTax(event) {
        var curProduct = this.state.curProduct;
        curProduct.TaxRate = event.target.value;
        this.setState({ curProduct: curProduct });
    }

    changeDescription(event) {
        var curProduct = this.state.curProduct;
        curProduct.Description = event.target.value;
        this.setState({ curProduct: curProduct });
    }

    changeCategory(event) {
        var curProduct = this.state.curProduct;
        curProduct.CategoryId = event.target.value;
        this.setState({ curProduct: curProduct });
    }

    changeFee(event) {
        var curProduct = this.state.curProduct;
        curProduct.FeeOption = event.target.value;
        this.setState({ curProduct: curProduct });
    }

    changePicture(event) {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            
            this.setState({
              tempPicture: URL.createObjectURL(file),
              picFile: file
            });
        } else {
            this.setState({picFile: null, tempPicture: ""});
        }
    }

    changeStock(event) {
        var curProduct = this.state.curProduct;
        curProduct.Inventory = event.target.value;
        this.setState({ curProduct: curProduct });
    }

    changeVariations(event) {
        this.setState({ isVariations: event.target.checked });
    }

    changeOptions(event) {
        this.setState({ isOptions: event.target.checked });
    }

    changeInventory(event) {
        this.setState({ isInventory: event.target.checked });
    }

    changeVariationName = (variationId, event) => {
        console.log(variationId);
        var curProduct = this.state.curProduct;
        var variations = curProduct.Variations.filter(function( v ) {
            return v.VariationId == variationId;
        });

        if (variations.length > 0) {
            variations[0].Name = event.target.value;
            this.setState({curProduct: curProduct});
        }
    }

    changeVariationCost = (variationId, event) => {
        console.log(variationId);
        var curProduct = this.state.curProduct;
        var variations = curProduct.Variations.filter(function( v ) {
            return v.VariationId == variationId;
        });

        if (variations.length > 0) {
            variations[0].Cost = event.target.value;
            this.setState({curProduct: curProduct});
        }
    }

    onClickAddVariation = (event) => {
        event.preventDefault();
        console.log(event);

        var curProduct = this.state.curProduct;
        var tempVariation = curProduct.Variations.filter(function( v ) {
            return v.VariationId == 0;
        });

        if (tempVariation.length > 0) {
            this.setState({variationError: "Please save the new variation first!"});
        } else {
            var variantion = {... mockVariation};
            curProduct.Variations.push(variantion);
            this.setState({curProduct: curProduct, variationError: ""});
        }
    }

    changeOptionName = (optionId, event) => {
        console.log(optionId);
        var curProduct = this.state.curProduct;
        var options = curProduct.Options.filter(function( o ) {
            return o.OptionId == optionId;
        });

        if (options.length > 0) {
            options[0].Name = event.target.value;
            this.setState({curProduct: curProduct});
        }
    }

    changeOptionCost = (optionId, event) => {
        var curProduct = this.state.curProduct;
        var options = curProduct.Options.filter(function( o ) {
            return o.OptionId == optionId;
        });

        if (options.length > 0) {
            options[0].Cost = event.target.value;
            this.setState({curProduct: curProduct});
        }
    }

    onClickAddOption = (event) => {
        var curProduct = this.state.curProduct;
        var tempOption = curProduct.Options.filter(function( o ) {
            return o.OptionId == 0;
        });

        if (tempOption.length > 0) {
            this.setState({optionError: "Please save the new option first!"});
        } else {
            var option = {... mockOption};
            curProduct.Options.push(option);
            this.setState({curProduct: curProduct, optionError: ""});
        }
    }

    onClickSaveInventory = (event) => {
        event.preventDefault();
        console.log(event);

        const {curProduct} = this.state;

        var inventoryError = "";
        if (curProduct.Inventory == "") {
            inventoryError = "Please input stock";
        }

        if (inventoryError == "") {
            this.props.dispatch(updateInventoryRequest(
                curProduct
            ))
        }
        this.setState({inventoryError: inventoryError});
    }

    getProductById = (id) => {
        const {products} = this.props;
        var data = products.filter(function( p ) {
            return p.ProductId == id;
        });

        if (data.length > 0) {
            return data[0];
        } else {
            return mockProduct;
        }
    }

    getCategoryById = (id) => {
        let category = this.props.categories.filter(function( c ) {
            return c.CategoryId == id;
        });

        if (category.length > 0) {
            return category[0]
        } else {
            return null;
        }
    }
    
    getProducts = () => {
        const {curCategoryId, products} = this.props;

        if (curCategoryId != 0) {
            var data = products.filter(function( p ) {
                return p.CategoryId == curCategoryId;
            });
    
            return data;
        } else {
            return products;
        }
    }

    goBack = () => {
        this.props.history.push('/admin/products/prod_list');
    }

    createNewProduct = () => {
        this.props.history.push('/app/ecommerce/management/create');
    }

    onClickSaveProduct = (e) => {
        e.preventDefault();

        var prodError = "";
        const {curProduct, picFile} = this.state;
        const {merchant, history} = this.props;

        console.log("curProduct");
        console.log(curProduct);

        if (curProduct.Name === "") {
            prodError = "Please input name";
        } else if (curProduct.Price === "") {
            prodError = "Please input price";
        } else if (curProduct.SKU === "") {
            prodError = "Please input SKU";
        } else if (curProduct.TaxRate === "") {
            prodError = "Please input tax rate";
        } 

        if (prodError == "") {
            if (curProduct.ProductId == 0) {
                this.props.dispatch(createProductRequest({
                    curProduct,
                    picFile,
                    merchantId: merchant.MerchantId,
                    history
                }))
            } else {
                this.props.dispatch(updateProductRequest({
                    curProduct,
                    picFile,
                    merchantId: merchant.MerchantId,
                    history
                }))
            }
        }
        this.setState({prodError: prodError});
    }

    onClickSaveVariation = (e, variationId) => {
        e.preventDefault();

        var curProduct = this.state.curProduct;
        var variations = curProduct.Variations.filter(function( v ) {
            return v.VariationId == variationId;
        });

        var variation = variations[0];

        var error = "";
        if (variation.Name == "") {
            error = "Please input name";
        } else if (variation.Cost == "") {
            error = "Please input price";
        }
        if (error == "") {
            if (variation.VariationId == 0) {
                this.props.dispatch(createVariationRequest({
                    productId: curProduct.ProductId,
                    variation
                }))
            } else {
                this.props.dispatch(updateVariationRequest({
                    productId: curProduct.ProductId,
                    variation
                }))
            }
        } 
        this.setState({variationError: error});
    }

    onClickDeleteVariation = (e, variationId) => {
        e.preventDefault();

        var curProduct = this.state.curProduct;
        if (variationId == 0) {
            let index = curProduct.Variations.findIndex(v => v.VariationId === 0);
            curProduct.Variations.splice(index, 1);
            this.setState({curProduct: curProduct});
        } else {
            this.props.dispatch(deleteVariationRequest({
                productId: curProduct.ProductId,
                variationId
            }))
        }
    }

    onClickSaveOption = (e, optionId) => {
        e.preventDefault();

        var curProduct = this.state.curProduct;
        var options = curProduct.Options.filter(function( o ) {
            return o.OptionId == optionId;
        });
        
        var option = options[0];

        var error = "";
        if (option.Name == "") {
            error = "Please input name";
        } 
        if (error == "") {
            if (option.OptionId == 0) {
                this.props.dispatch(createOptionRequest({
                    productId: curProduct.ProductId,
                    option
                }))
            } else {
                this.props.dispatch(updateOptionRequest({
                    productId: curProduct.ProductId,
                    option
                }))
            }
        } 
        this.setState({optionError: error});
    }

    onClickDeleteOption = (e, optionId) => {
        e.preventDefault();
     
        var curProduct = this.state.curProduct;
        if (optionId == 0) {
            let indexToOption = curProduct.Options.findIndex(o => o.OptionId === 0);
            curProduct.Options.splice(indexToOption, 1);
            this.setState({curProduct: curProduct});
        } else {
            this.props.dispatch(deleteOptionRequest({
                productId: curProduct.ProductId,
                optionId
            }))
        }
    }

    render() {
       
        const {curProduct, prodError, variationError, optionError, inventoryError, tempPicture, isVariations, isOptions, isInventory} = this.state;
        const {categories} = this.props;

        return (
            <div className="row no-gutters prod_edit">
                <div className="col">
                    <div className="bg-second p-4">
                        <div className={`${s.subHeader}`}>
                            <a className="back-link" href="#" onClick={this.goBack}><i className="zmdi zmdi-arrow-left"></i></a>
                            <h3 className="mt-0 mb-5 text-white">Product Details</h3>
                        </div>
                        <form>
                            <div className={`row ${s.imageContainer}`}>
                                <div className="upload-box mt-1 mb-4 mx-auto">
                                    <label htmlFor="img" className="img m-0 active">
                                        <i className="zmdi zmdi-image-alt"></i>
                                        <input id="img" type="file" onChange={this.changePicture}/>
                                        <span>Upload item image</span>
                                    </label>
                                </div>
                                {
                                    (tempPicture == "" || tempPicture == null) && (curProduct.Picture == "" || curProduct.Picture == null) ? (
                                        <div></div>
                                    ) : (
                                        <div className="col-md-6">
                                            {
                                                tempPicture != "" ? (
                                                    <img src={tempPicture} alt="..." className={s.image} title="image"/>
                                                ) : (
                                                    <img src={curProduct.Picture} alt="..." className={s.image} title="image"/>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            <div className="row alert-container">
                            {
                                prodError && (
                                    <Alert className="alert-sm" color="danger">
                                        {prodError}
                                    </Alert>
                                )
                            }
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Product Name</label>
                                        <input type="text" className="form-control" placeholder="" required="" value={curProduct.Name} onChange={this.changeName}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Price (In $)</label>
                                        <input type="number" className="form-control" placeholder="" required="" value={curProduct.Price} onChange={this.changePrice}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Category</label>
                                        <select className={`form-control ${s.select}`} value={curProduct.CategoryId} onChange={this.changeCategory}>
                                        {categories.map((category, i) => 
                                            <option value={category.CategoryId} key={category.CategoryId}>{category.Name}</option>
                                        )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Card Fee Options</label>
                                        <select className={`form-control ${s.select}`} value={curProduct.FeeOption} onChange={this.changeFee}>
                                        {mockFeeOptions.map((fee, i) => 
                                            <option value={fee.Id} key={fee.Id}>{fee.Name}</option>
                                        )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>SKU</label>
                                        <input type="text" className="form-control" placeholder="" required="" value={curProduct.SKU} onChange={this.changeSku}/>
                                    </div>
                                </div>
                                {/* <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Discount %</label>
                                        <input type="number" className="form-control" placeholder="" required="" value={curProduct.FeeOption} onChange={this.changeFee}/>
                                    </div>
                                </div> */}
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Tax Rate %</label>
                                        <input type="number" className="form-control" placeholder="" required="" value={curProduct.TaxRate} onChange={this.changeTax}/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Product description</label>
                                <textarea className={`form-control ${s.textArea}`} value={curProduct.Description} onChange={this.changeDescription}></textarea>
                            </div>
                            <div className={`form-group col-md-12 ${s.btnContainer}`}>
                                <a href="#" className="btn btn-save btn-sm mt-2" onClick={(e) => this.onClickSaveProduct(e)} >Save Product Details</a>
                            </div>
                            {
                                curProduct.ProductId == 0 ? (
                                    <div></div>
                                ) : (
                                    <div className="">
                                        <div className="d-flex align-items-center px-4 mb-3 text-white py-2 mt-5 switch-toolbar">
                                            Add Variations
                                            <label className="switch ml-auto my-1">
                                                <input type="checkbox" checked={isVariations} onChange={this.changeVariations}/>
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <div className="row alert-container">
                                        {
                                            variationError && (
                                                <Alert className="alert-sm" color="danger">
                                                    {variationError}
                                                </Alert>
                                            )
                                        }
                                        </div>
                                        {
                                            isVariations ? (
                                                <div>
                                                    {curProduct.Variations.map((variation, i) => 
                                                        <div key={variation.VariationId}>
                                                            <div className="row">
                                                                <div className="col-md-5">
                                                                    <div className="form-group">
                                                                        <label>Option</label>
                                                                        <input type="text" className="form-control" placeholder="" required="" 
                                                                            value={variation.Name} onChange={(e) => this.changeVariationName(variation.VariationId, e)}/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label>Price (In $)</label>
                                                                        <input type="number" className="form-control" placeholder="" required="" 
                                                                            value={variation.Cost} onChange={(e) => this.changeVariationCost(variation.VariationId, e)}/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group variation-action">
                                                                        <label>Action</label>
                                                                        <div className="variation-button-group">
                                                                            <a href="#" className="btn btn-delete btn-sm float-right" onClick={(e) => this.onClickDeleteVariation(e, variation.VariationId)} >Delete</a>
                                                                            <a href="#" className="btn btn-save btn-sm float-right" onClick={(e) => this.onClickSaveVariation(e, variation.VariationId)} >Save</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    
                                                    <div className="col-md-12">
                                                        <button className="btn-outline-success" type="button" onClick={(e) => this.onClickAddVariation(e)}><i className="zmdi zmdi-plus"></i> Add another Variation</button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div></div>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </form>
                    </div>   
                </div>
                <div className="col second-box">
                    <div className="bg-second p-4">
                    {
                        curProduct.ProductId == 0 ? (
                            <div></div>
                        ) : (
                            <div className={`row no-gutters w-100 ${s.optionContainer}`}>
                                <div className="col-12">
                                    <div className="d-flex align-items-center px-4 mb-3 text-white py-2 switch-toolbar">
                                        Add Options
                                        <label className="switch ml-auto my-1">
                                            <input type="checkbox" checked={isOptions} onChange={this.changeOptions}/>
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 alert-container">
                                {
                                    optionError && (
                                        <Alert className="alert-sm" color="danger">
                                            {optionError}
                                        </Alert>
                                    )
                                }
                                </div>
                                {
                                    isOptions ? (
                                        <form className="col-12">
                                            {curProduct.Options.map((option, i) => 
                                                <div key={option.OptionId} >
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <div className="form-group">
                                                                <label>Option</label>
                                                                <input type="text" className="form-control" placeholder="" required="" 
                                                                    value={option.Name} onChange={(e) => this.changeOptionName(option.OptionId, e)}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label>Price (In $)</label>
                                                                <input type="number" className="form-control" placeholder="" required="" 
                                                                    value={option.Cost} onChange={(e) => this.changeOptionCost(option.OptionId, e)}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group variation-action">
                                                                <label>Action</label>
                                                                <div className="variation-button-group">
                                                                    <a href="#" className="btn btn-delete btn-sm float-right" onClick={(e) => this.onClickDeleteOption(e, option.OptionId)} >Delete</a>
                                                                    <a href="#" className="btn btn-save btn-sm float-right" onClick={(e) => this.onClickSaveOption(e, option.OptionId)} >Save</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-md-12">
                                                <button className="btn-outline-success" type="button" onClick={(e) => this.onClickAddOption(e)}><i className="zmdi zmdi-plus"></i> Add another Option</button>
                                            </div>
                                        </form>
                                    ) : (
                                        <div></div>
                                    )
                                }
                            </div>
                        )
                    }
                    {
                        curProduct.ProductId == 0 ? (
                            <div></div>
                        ) : (
                            <div className="row no-gutters w-100">
                                <div className="col-12">
                                    <div className="d-flex align-items-center px-4 mb-3 text-white py-2 switch-toolbar">
                                        Manage Inventory
                                        <label className="switch ml-auto my-1">
                                            <input type="checkbox" checked={isInventory} onChange={this.changeInventory}/>
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 alert-container">
                                {
                                    inventoryError && (
                                        <Alert className="alert-sm" color="danger">
                                            {inventoryError}
                                        </Alert>
                                    )
                                }
                                </div>
                                {
                                    isInventory ? (
                                        <form className="col-12">
                                            <div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="number" className="form-control" placeholder="Available Stock" required="" value={curProduct.Inventory} onChange={this.changeStock}/>
                                                    </div>
                                                    <a href="#" className="btn btn-save btn-sm float-right" onClick={(e) => this.onClickSaveInventory(e)}>Save Inventory</a>
                                                </div>
                                            </div>
                                        </form>
                                    ) : (
                                        <div></div>
                                    )
                                }
                            </div>
                        )
                    }
                    </div>   
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        //products: getProductsByCategoryId(state),
        products: state.products.data,
        categories: state.products.categories,
        curCategoryId: state.products.curCategoryId,
        curProductId: state.products.curProductId,
        merchant: state.auth.merchant,
        productDefaults: state.settings.productDefaults,
        isReceiving: state.products.isReceiving,
        isUpdating: state.products.isUpdating,
        error: state.products.error,
        isDeleting: state.products.isDeleting,
        idToDelete: state.products.idToDelete,
    };
}

export default withRouter(connect(mapStateToProps)(ProductEdit));


import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import {
    BootstrapTable,
    TableHeaderColumn,
} from 'react-bootstrap-table';
import { connect } from 'react-redux';

import { Popover, PopoverBody, PopoverHeader, Alert } from 'reactstrap';

import {
    Button,
    ButtonToolbar,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";

import Loader from '../../../components/Loader/Loader';
import Widget from '../../../components/Widget/Widget';

import { loadAvailableStations, createStation, updateStation } from '../../../actions/settings'

import s from './Stations.module.scss';

class Stations extends React.Component {
    
    static propTypes = {
        products: PropTypes.array,
        dispatch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        products: []
    };

    state = {
        popovers: {},
        promoAlert: false,
        isAddModal: false,
        curStation: null,
        stationName: "",
        stationError: "",
        editNameError: ""
    };

    constructor() {
        super();
        
        this.changeNewStationName = this.changeNewStationName.bind(this);
        this.changeEditStationName = this.changeEditStationName.bind(this);
        this.editStation = this.editStation.bind(this);
        this.apiFormatter = this.apiFormatter.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(loadAvailableStations(this.props.merchant.MerchantId));
    }

    changeNewStationName(event) {
        this.setState({ stationName: event.target.value, stationError: "" });
    }

    changeEditStationName(event) {
        var curStation = this.state.curStation;
        curStation.Name = event.target.value;
        this.setState({ curStation: curStation, editNameError: "" });

        this.setState({ stationName: event.target.value, stationError: "" });
    }

    onClickSave = (event) => {
        event.preventDefault();
        console.log("ok")
        const { stationName } = this.state;
        const { merchant } = this.props;
        if (stationName == "") {
            this.setState({stationError: "Please input the station name"});
        } else {
            const payload = {
                MerchantId: merchant.MerchantId,
                Name: stationName,
                AssetId: null,
                DisplayWebsite: '',
                UserTipping: false,
                UserTouchscreen: false,
                EnableSelfCheckout: false,
                DisplayCart: false,
                StationMode: 1,
                Terminal: 'Unassigned'
            }
            this.props.dispatch(createStation(payload));
        }
    }

    onClickUpdate = (event) => {
        event.preventDefault();

        const { curStation } = this.state;

        var editNameError = "";
        if (curStation.Name == "") {
            editNameError = "Please input the station name";
        } 

        if (editNameError == "") {
            this.props.dispatch(updateStation(curStation));
            this.setState({isAddModal: false});
        } else {
            this.setState({editNameError: editNameError});
        }
    }

    editStation (stationId) {
        const station = this.getStationById(stationId);
        this.setState({isAddModal: true, curStation: station});
    }

    getStationById = (id) => {
        const {stations} = this.props;
        var data = stations.filter(function( s ) {
            return s.StationId == id;
        });

        var station = null;
        if (data.length > 0) {
            station = {... data[0]};
        } 
        return station;
    }

    hideAddModal = (e) => {
        e.preventDefault();
        this.setState({isAddModal: false});
    }

    apiFormatter(cell, row) {
        return (
            <ButtonToolbar className="settings-action">
                <button className="btn" onClick={()=> this.editStation(row.StationId)}>
                    <div><i className="zmdi zmdi-edit"></i></div>
                </button>
            </ButtonToolbar>
        )
    }

    renderSizePerPageDropDown = (props) => {
        const limits = [];
        props.sizePerPageList.forEach((limit) => {
            limits.push(<DropdownItem key={limit}
                                      onClick={() => props.changeSizePerPage(limit)}>{limit}</DropdownItem>);
        });

        return (
            <Dropdown isOpen={props.open} toggle={props.toggleDropDown}>
                <DropdownToggle color="default" caret>
                    {props.currSizePerPage}
                </DropdownToggle>
                <DropdownMenu>
                    {limits}
                </DropdownMenu>
            </Dropdown>
        );
    };

    render() {
        const options = {
            sizePerPage: 10,
            paginationSize: 3,
            sizePerPageDropDown: this.renderSizePerPageDropDown,
        };

        const { stationError, editNameError, curStation, isAddModal } = this.state;
        const { stations } = this.props;
        const data = stations;
        
        return (
            <div className="row no-gutters stations">
                <div className="col station">
                    <div className="bg-second p-4">
                        <div className="">
                            <h3 className="mt-0 mb-5 text-white">Station: <small className={`${s.description}`}> Create Stations to assign to Terminals</small></h3>
                        </div>
                        <form>
                            <div className="form-group station-name">
                                <label>Station Name</label>
                                <input type="text" className="form-control" placeholder="Server Station" onChange={this.changeNewStationName}/>
                                <p className="px-4">Terminal: <span className="font-italic"> Unassigned</span></p>
                            </div>
                            <div className="row alert-container">
                            {
                                stationError && (
                                    <Alert className="alert-sm" color="danger">
                                        {stationError}
                                    </Alert>
                                )
                            }
                            </div>
                            <button type="submit" className="btn pt-2 btn-block" onClick={(e) => this.onClickSave(e)}>Save Station</button>
                        </form>
                    </div>
                    <div className="m-4 alert alert-light alert-dismissible fade show" role="alert">
                        <strong>Note:</strong> Terminals can only be assigned to Stations in BistroPOS. Once a Station is created here, you must assign a Terminal by navigating to the <b>Settings</b> menu then <b>Terminals</b> Tab in the Bistro Application.
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="false">×</span>
                        </button>
                    </div>   
                </div>
                <div className="col second-box">
                    <div className="bg-second p-4">
                        <div className="form-group">
                            <div className={`d-flex align-items-center px-2 mb-3 text-white py-2 ${s.available_stations}`}>
                                <h4>
                                    Available Stations <small > </small>
                                </h4>
                            </div>  
                        </div>  
                        <Widget>
                            <div className="order_list">
                                <BootstrapTable data={data} tableHeaderClass='list_header' bordered={ false } striped={true} version="4" pagination options={options} 
                                                tableContainerClass={`table-striped ${s.bootstrapTable}`}>
                                    <TableHeaderColumn dataField="Name" className="width-10p"
                                                    columnClassName="width-10p">
                                        <span className="fs-sm">Station Name</span>
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="Terminal" className="width-15p"
                                                    columnClassName="width-15p">
                                        <span className="fs-sm">Terminal</span>
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="StationId" isKey={true}  dataFormat={this.apiFormatter} className="width-10p"
                                                    columnClassName="width-10p">
                                        <span className="fs-sm"></span>
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </Widget>
                    </div>   
                </div>
                <Drawer anchor="right" open={isAddModal} onClose={this.hideAddModal}>
                    <div className="add_category_model add_expenses receipt_model px-0" 
                        id="add_expenses" tabIndex="-1" role="dialog" aria-labelledby="receipt_modelTitle" aria-modal="true">
                        <div className="modal-dialog modal-dialog-scrollable" role="document">
                            <div className="modal-content">
                                <div className="modal-header px-0">
                                    <h2 className="col-10 mx-auto">Edit Station Name</h2>
                                </div>
                                <div className="row alert-container">
                                {
                                    editNameError && (
                                        <Alert className="alert-sm" color="danger">
                                            {editNameError}
                                        </Alert>
                                    )
                                }
                                </div>
                                <div className="modal-body p-0">
                                    <form>
                                        <div className="col-10 mx-auto form_container">
                                            {
                                                curStation == null ? (
                                                    <div></div>
                                                ) : (
                                                    <div className="form-group">
                                                        <label>Station Name</label>
                                                        <input type="text" className="form-control" value={curStation.Name} onChange={this.changeEditStationName}/>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className="modal-footer">
                                            <div className="row no-gutters w-100">
                                                <div className="col-6"> <button type="file" className="btn Cencel" onClick={(e) => this.hideAddModal(e)}>Cancel</button></div>
                                                <div className="col-6"> <button type="file" className="btn" onClick={(e) => this.onClickUpdate(e)}>Update</button></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Drawer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        merchant: state.auth.merchant,
        stations: state.settings.stations,
        isReceiving: state.settings.isReceiving,
        isUpdating: state.settings.isUpdating,
        isDeleting: state.settings.isDeleting,
        idToDelete: state.settings.idToDelete,
    };
}

export default withRouter(connect(mapStateToProps)(Stations));


import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';

import {
    BootstrapTable,
    TableHeaderColumn,
} from 'react-bootstrap-table';
import { connect } from 'react-redux';

import { Popover, PopoverBody, PopoverHeader, Alert } from 'reactstrap';

import {
    Button,
    ButtonToolbar,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";

import Loader from '../../../../components/Loader/Loader';
import Widget from '../../../../components/Widget/Widget';

import s from './WebSites.module.scss';

import { loadWebsites, createWebsite, updateWebsite, deleteWebsite } from '../../../../actions/settings'

class WebSites extends React.Component {
    
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    };

    state = {
        popovers: {},
        promoAlert: false,
        isAddModal: false,
        siteUrl: "",
        siteName: "",
        curSite: null,
        siteError: "",
        editError: ""
    };

    constructor() {
        super();

        this.changeName = this.changeName.bind(this);
        this.changeUrl = this.changeUrl.bind(this);
        this.changeEditSiteName = this.changeEditSiteName.bind(this);
        this.changeEditSiteUrl = this.changeEditSiteUrl.bind(this);
        this.apiFormatter = this.apiFormatter.bind(this);
    }

    componentDidMount() {
      //  this.props.dispatch(loadWebsites());
    }

    changeName(event) {
        this.setState({ siteName: event.target.value, siteError: "" });
    }

    changeUrl(event) {
        this.setState({ siteUrl: event.target.value, siteError: ""  });
    }

    changeEditSiteName(event) {
        var curSite = this.state.curSite;
        curSite.Name = event.target.value;
        this.setState({ curSite: curSite, editError: "" });
    }

    changeEditSiteUrl(event) {
        var curSite = this.state.curSite;
        curSite.Url = event.target.value;
        this.setState({ curSite: curSite, editError: "" });
    }

    onClickSaveSite = (e) => {
        e.preventDefault();

        var siteError = "";
        const {siteName, siteUrl} = this.state;
        
        if (siteName == "") {
            siteError = "Please input a site name";
        } else if (siteUrl == "") {
            siteError = "Please input a site url";
        } 

        if (siteError == "") {
            this.props.dispatch(createWebsite({
                AssetName: siteName,
                Url: siteUrl
            }))
        }
        this.setState({siteError: siteError});
    }

    onClickUpdate = (event) => {
        event.preventDefault();

        const { curSite } = this.state;

        var editError = "";
        if (curSite.Name == "") {
            editError = "Please input the website name";
        } 

        if (curSite.Url == "") {
            editError = "Please input the website url";
        } 

        if (editError == "") {
            this.props.dispatch(updateWebsite({
                AssetId: curSite.AssetId,
                AssetName: curSite.Name,
                Url: curSite.Url
            }));
            this.setState({isAddModal: false});
        } else {
            this.setState({editError: editError});
        }
    }

    editSite (id) {
        const site = this.getSiteById(id);
        this.setState({isAddModal: true, curSite: site});
    }

    getSiteById = (id) => {
        const {websites} = this.props;
        var data = websites.filter(function( w ) {
            return w.AssetId == id;
        });

        var site = null;
        if (data.length > 0) {
            site = {... data[0]};
        } 
        return site;
    }

    removeSite(id) {
        this.togglePopover(id);
        this.props.dispatch(deleteWebsite(id));
    }

    hideAddModal = (e) => {
        e.preventDefault();
        this.setState({isAddModal: false});
    }

    togglePopover(id) {
        let newState = {...this.state};
        if (!this.state.popovers[id]) {
            newState.popovers[id] = true;
        } else {
            newState.popovers[id] = !newState.popovers[id];
        }
        this.setState(newState);
    }

    apiFormatter(cell, row) {
        return (
            <ButtonToolbar className="settings-action">
                <button id={'popoverDelete_' + row.AssetId} className="btn">
                    {this.props.isDeleting && this.props.idToDelete === row.AssetId ? <Loader size={14}/> :
                        <div><i className="zmdi zmdi-delete"></i></div>
                    }
                </button>
                <button className="btn" onClick={()=> this.editSite(row.AssetId)}>
                    <div><i className="zmdi zmdi-edit"></i></div>
                </button>
                
                <Popover className="popover-danger" target={'popoverDelete_' + row.AssetId} placement="top" isOpen={this.state.popovers[row.AssetId]}
                         toggle={()=>{this.togglePopover(row.AssetId)}}
                >
                    <PopoverHeader className="px-5">Are you sure?</PopoverHeader>
                    <PopoverBody className="px-5 d-flex justify-content-center">
                        <ButtonToolbar>
                            <Button color="success" size="xs" onClick={() => {this.removeSite(row.AssetId)}}>
                                Yes
                            </Button>
                            <Button color="danger" size="xs" onClick={() => {this.togglePopover(row.AssetId)}}>
                                No
                            </Button>
                        </ButtonToolbar>
                    </PopoverBody>
                </Popover>
            </ButtonToolbar>
        )
    }

    renderSizePerPageDropDown = (props) => {
        const limits = [];
        props.sizePerPageList.forEach((limit) => {
            limits.push(<DropdownItem key={limit}
                                      onClick={() => props.changeSizePerPage(limit)}>{limit}</DropdownItem>);
        });

        return (
            <Dropdown isOpen={props.open} toggle={props.toggleDropDown}>
                <DropdownToggle color="default" caret>
                    {props.currSizePerPage}
                </DropdownToggle>
                <DropdownMenu>
                    {limits}
                </DropdownMenu>
            </Dropdown>
        );
    };
    
    render() {
        const options = {
            sizePerPage: 10,
            paginationSize: 3,
            sizePerPageDropDown: this.renderSizePerPageDropDown,
        };

        const {curSite, siteError, siteName, siteUrl, editError, isAddModal} = this.state;
        const { websites } = this.props;
        const data = websites;

        return (
            <div className={`${s.websites}`}>
                <div className="form-group">
                    <div className={`d-flex align-items-center px-2 mb-3 text-white py-2 ${s.sub_header}`}>
                        <h4>
                            Default URL's - <small > URL's for web Pages you would like available for display </small>
                        </h4>
                    </div>  
                </div> 
                <form>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="form-group">
                                <label>Website URL</label>
                                <input type="text" className="form-control" placeholder="www.Rebelity.com" required="" value={siteUrl} onChange={this.changeUrl}/>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-group">
                                <label>Website Name</label>
                                <input type="text" className="form-control" placeholder="Rebelity Software" required="" value={siteName} onChange={this.changeName}/>
                            </div>
                        </div>
                    </div>
                    <div className="row alert-container">
                    {
                        siteError && (
                            <Alert className="alert-sm" color="danger">
                                {siteError}
                            </Alert>
                        )
                    }
                    </div>
                    <div className={`form-group col-md-12 ${s.btnContainer}`}>
                        <a href="#" className="btn btn-save btn-sm mt-2" onClick={(e) => this.onClickSaveSite(e)} >Save URL(s)</a>
                    </div>
                </form>
                <Widget>
                    <div className="order_list">
                        <BootstrapTable data={data} tableHeaderClass='list_header' bordered={ false } striped={true} version="4" pagination options={options} 
                                        tableContainerClass={`table-striped ${s.bootstrapTable}`}>
                            <TableHeaderColumn dataField="Name" className="width-10p"
                                            columnClassName="width-10p">
                                <span className="fs-sm">Website Name</span>
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="Url" className="width-10p"
                                            columnClassName="width-10p">
                                <span className="fs-sm">Url</span>
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="AssetId" isKey={true}  dataFormat={this.apiFormatter} className="width-10p"
                                            columnClassName="width-10p">
                                <span className="fs-sm"></span>
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </Widget>
                <Drawer anchor="right" open={isAddModal} onClose={this.hideAddModal}>
                    <div className="add_category_model add_expenses receipt_model px-0" 
                        id="add_expenses" tabIndex="-1" role="dialog" aria-labelledby="receipt_modelTitle" aria-modal="true">
                        <div className="modal-dialog modal-dialog-scrollable" role="document">
                            <div className="modal-content">
                                <div className="modal-header px-0">
                                    <h2 className="col-10 mx-auto">Edit Website Name</h2>
                                </div>
                                <div className="row alert-container">
                                {
                                    editError && (
                                        <Alert className="alert-sm" color="danger">
                                            {editError}
                                        </Alert>
                                    )
                                }
                                </div>
                                <div className="modal-body p-0">
                                    <form>
                                        <div className="col-10 mx-auto form_container">
                                            {
                                                curSite == null ? (
                                                    <div></div>
                                                ) : (
                                                    <div>
                                                        <div className="form-group">
                                                            <label>Website Name</label>
                                                            <input type="text" className="form-control" value={curSite.Name} onChange={this.changeEditSiteName}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Url</label>
                                                            <input type="text" className="form-control" value={curSite.Url} onChange={this.changeEditSiteUrl}/>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className="modal-footer">
                                            <div className="row no-gutters w-100">
                                                <div className="col-6"> <button className="btn Cencel" onClick={(e) => this.hideAddModal(e)}>Cancel</button></div>
                                                <div className="col-6"> <button className="btn" onClick={(e) => this.onClickUpdate(e)}>Update</button></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Drawer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        websites: state.settings.websites,
        isReceiving: state.settings.isReceiving,
        isUpdating: state.settings.isUpdating,
        isDeleting: state.settings.isDeleting,
        idToDelete: state.settings.idToDelete,
    };
}

export default withRouter(connect(mapStateToProps)(WebSites));


import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';

import {
    BootstrapTable,
    TableHeaderColumn,
} from 'react-bootstrap-table';
import { connect } from 'react-redux';

import { Popover, PopoverBody, PopoverHeader, Alert } from 'reactstrap';

import {
    Button,
    ButtonToolbar,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";

import Widget from '../../../components/Widget/Widget';
import Pictures from './Pictures/Pictures';
import WebSites from './WebSites/WebSites';
import Videos from './Videos/Videos';

import s from './CustomerScreen.module.scss';

import { loadStationSettings } from '../../../actions/settings'

class CustomerScreen extends React.Component {
    
    static propTypes = {
        products: PropTypes.array,
        dispatch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        
    };

    state = {
        popovers: {},
        promoAlert: false,
    };

    constructor() {
        super();
    }

    componentDidMount() {
        this.props.dispatch(loadStationSettings());
    }
    render() {
        
        return (
            <div className="row no-gutters stations">
                <div className="col station">
                    <Pictures/>
                </div>
                <div className="col second-box">
                    <div className="bg-second p-4">
                        <WebSites/>
                        <Videos/>
                    </div>   
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isReceiving: state.settings.isReceiving,
        isUpdating: state.settings.isUpdating,
        isDeleting: state.settings.isDeleting,
        idToDelete: state.settings.idToDelete,
    };
}

export default withRouter(connect(mapStateToProps)(CustomerScreen));


import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { setCategoryId } from '../../actions/products'

import Stations from './Stations/Stations';
import CustomerScreen from './CustomerScreen/CustomerScreen';
import StoreSettings from './StoreSettings/StoreSettings';

import Loader from '../../components/Loader/Loader';

class Settings extends React.Component {

    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    };

    render() {
        const { pathname } = this.props.location;

        const {isReceiving, isUpdating, isDeleting} = this.props;

        return (
            <div className="body_wrapper">
                <div className="left_sidebar">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <Link className={pathname.startsWith('/admin/settings/store_settings') ? "nav-item nav-link active" : "nav-item nav-link"} to="/admin/settings/store_settings" onClick={this.onClickCustomerScreen}>Store Settings</Link>
                        <Link className={pathname.startsWith('/admin/settings/stations') ? "nav-item nav-link active" : "nav-item nav-link"} to="/admin/settings/stations" onClick={this.onClickStations}>Stations</Link>
                        <Link className={pathname.startsWith('/admin/settings/customer_screen') ? "nav-item nav-link active" : "nav-item nav-link"} to="/admin/settings/customer_screen" onClick={this.onClickCustomerScreen}>Customer Screen</Link>
                    </div>
                </div>
                <div className="right_sidebar">
                    {
                        isReceiving || isUpdating || isDeleting ? (<Loader size={80}/>) : (<div></div>)
                    }
                    <TransitionGroup>
                        <CSSTransition
                        key={this.props.location.key}
                        classNames="fade"
                        timeout={600}
                        >
                        <Switch>
                            <Route path="/admin/settings" exact render={() => <Redirect to="/admin/settings/store_settings" />} />
                            <Route path="/admin/settings/store_settings" exact component={StoreSettings} />
                            <Route path="/admin/settings/stations" exact component={Stations} />
                            <Route path="/admin/settings/customer_screen" exact component={CustomerScreen} />
                        </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isReceiving: state.settings.isReceiving,
        isUpdating: state.settings.isUpdating,
        isDeleting: state.settings.isDeleting,
        idToDelete: state.settings.idToDelete,
    };
}

export default withRouter(connect(mapStateToProps)(Settings));


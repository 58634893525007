import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import {
    BootstrapTable,
    TableHeaderColumn,
} from 'react-bootstrap-table';
import { connect } from 'react-redux';

import { Popover, PopoverBody, PopoverHeader, Alert } from 'reactstrap';

import {
    Button,
    ButtonToolbar,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";

import { getProductsRequest, deleteProductRequest, setCurProductId, loadProductRequest } from '../../../actions/products'
import { mockProduct } from '../../../model/mockProduct'
import { getProductsByCategoryId } from '../../../selectors/products';

import Loader from '../../../components/Loader/Loader';
import Widget from '../../../components/Widget/Widget';
import s from './ProdList.module.scss';

import cx from 'classnames';

class ProdList extends React.Component {
    
    static propTypes = {
        products: PropTypes.array,
        dispatch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        products: []
    };

    state = {
        popovers: {},
        promoAlert: false,
    };

    constructor() {
        super();
        this.apiFormatter = this.apiFormatter.bind(this);
    }

    componentDidMount() {
        if (this.props.products.length < 1) {
            console.log("load full products ")
            this.props.dispatch(getProductsRequest(this.props.merchant.MerchantId));
        }
    }

    showPromoAlert() {
        this.setState({promoAlert: true});
    }

    imageFormatter(cell) {
        if (cell == "" || cell == null ) {
            return (<div></div>);
        } else {
            return (
                <img src={cell} alt="..." className={s.image} title="image"/>
            )
        }
    }

    getCategoryById = (id) => {
        let category = this.props.categories.filter(function( c ) {
            return c.CategoryId == id;
        });

        if (category.length > 0) {
            return category[0]
        } else {
            return null;
        }
    }
    
    categoryFormatter = (cell) => {
        if (cell) {
            let category = this.getCategoryById(cell);
            return category == null ? "" : category.Name;
        } else {
            return ""
        }
    }

    variationsFormatter = (cell) => {
        return cell ? (
            <div className="variantions_col"><span>{cell.length}</span><i className="zmdi zmdi-storage"></i></div>
        ) : ""
    }

    getProducts = () => {
        const {curCategoryId, products} = this.props;

        if (curCategoryId != 0) {
            var data = products.filter(function( p ) {
                return p.CategoryId == curCategoryId;
            });
    
            return data;
        } else {
            return products;
        }
    }

    editProduct(id) {
        //const product = this.getProductById(id);
        this.props.dispatch(setCurProductId(id));
        this.props.history.push('/admin/products/prod_list/create');
    }

    getProductById = (id) => {
        const {products} = this.props;
        var data = products.filter(function( p ) {
            return p.ProductId == id;
        });

        var product = {... mockProduct};
        if (data.length > 0) {
            product = {... data[0]};
        }
            
        return product;
    }

    deleteProduct(id) {
        this.togglePopover(id);
        this.props.dispatch(deleteProductRequest(id));
    }

    togglePopover(id) {
        let newState = {...this.state};
        if (!this.state.popovers[id]) {
            newState.popovers[id] = true;
        } else {
            newState.popovers[id] = !newState.popovers[id];
        }
        this.setState(newState);
    }

    apiFormatter(cell, row) {
        return (
            <ButtonToolbar className="">
                <button id={'popoverDelete_' + row.ProductId} className="btn">
                    {this.props.isDeleting && this.props.idToDelete === row.ProductId ? <Loader size={14}/> :
                        <div><i className="zmdi zmdi-delete"></i></div>
                    }
                </button>
                <button className="btn" onClick={()=> this.editProduct(row.ProductId)}>
                    <div><i className="zmdi zmdi-edit"></i></div>
                </button>
                
                <Popover className="popover-danger" target={'popoverDelete_' + row.ProductId} placement="top" isOpen={this.state.popovers[row.ProductId]}
                         toggle={()=>{this.togglePopover(row.ProductId)}}
                >
                    <PopoverHeader className="px-5">Are you sure?</PopoverHeader>
                    <PopoverBody className="px-5 d-flex justify-content-center">
                        <ButtonToolbar>
                            <Button color="success" size="xs" onClick={() => {this.deleteProduct(row.ProductId)}}>
                                Yes
                            </Button>
                            <Button color="danger" size="xs" onClick={() => {this.togglePopover(row.ProductId)}}>
                                No
                            </Button>
                        </ButtonToolbar>
                    </PopoverBody>
                </Popover>
            </ButtonToolbar>
        )
    }

    renderSizePerPageDropDown = (props) => {
        const limits = [];
        props.sizePerPageList.forEach((limit) => {
            limits.push(<DropdownItem key={limit}
                                      onClick={() => props.changeSizePerPage(limit)}>{limit}</DropdownItem>);
        });

        return (
            <Dropdown isOpen={props.open} toggle={props.toggleDropDown}>
                <DropdownToggle color="default" caret>
                    {props.currSizePerPage}
                </DropdownToggle>
                <DropdownMenu>
                    {limits}
                </DropdownMenu>
            </Dropdown>
        );
    };

    render() {
        const options = {
            sizePerPage: 10,
            paginationSize: 3,
            sizePerPageDropDown: this.renderSizePerPageDropDown,
        };

        const data = this.getProducts();

        return (
            <div className="prod_list">
                <div className="tab_header">
                    <h1 className="d-flex">
                        <span className="d-block">Products</span>
                        <button type="button" className="btn" onClick={() => this.editProduct(0)}>Add New</button>
                    </h1>
                    {/* <form className="search_box">
                        <div className="form-group d-flex">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><i className="zmdi zmdi-search"></i></div>
                            </div>
                            <input type="text" className="form-control" placeholder="Search"/>
                        </div>
                    </form> */}
                </div>
                <Widget>
                    <div className="order_list">
                        <BootstrapTable data={data} tableHeaderClass='list_header' bordered={ false } striped={true} version="4" pagination options={options} 
                                        tableContainerClass={`table-striped ${s.bootstrapTable}`} search>
                            <TableHeaderColumn dataField="SKU" className="width-10p"
                                            columnClassName="width-10p">
                                <span className="fs-sm">SKU</span>
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="Picture" dataFormat={this.imageFormatter} className="width-10p"
                                            columnClassName="width-10p">
                                <span className="fs-sm">Image</span>
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="Name" className="width-15p"
                                            columnClassName="width-15p">
                                <span className="fs-sm">Product Name</span>
                            </TableHeaderColumn>
                            {/* <TableHeaderColumn dataField="img" dataFormat={this.imageFormatter}>
                                <span className="fs-sm">Image</span>
                            </TableHeaderColumn> */}
                            {window.innerWidth >= 768 && (
                                <TableHeaderColumn dataField="CategoryId" className="width-15p"
                                columnClassName="width-15p" dataFormat={this.categoryFormatter}>
                                    <span className="fs-sm">Category</span>
                                </TableHeaderColumn>
                            )}
                            {window.innerWidth >= 768 && (
                                <TableHeaderColumn dataField="Variations" dataFormat={this.variationsFormatter} className="width-10p"
                                columnClassName="width-10p">
                                    <span className="fs-sm">Variantions</span>
                                </TableHeaderColumn>
                            )}
                            {window.innerWidth >= 768 && (
                                <TableHeaderColumn dataField="Price" className="width-10p"
                                columnClassName="width-10p">
                                    <span className="fs-sm">Price($)</span>
                                </TableHeaderColumn>
                            )}
                            {window.innerWidth >= 768 && (
                                <TableHeaderColumn dataField="Inventory" className="width-10p"
                                columnClassName="width-10p">
                                    <span className="fs-sm">In Stock</span>
                                </TableHeaderColumn>
                            )}
                            <TableHeaderColumn dataField="ProductId" isKey={true}  dataFormat={this.apiFormatter} className="width-10p"
                                            columnClassName="width-10p">
                                <span className="fs-sm">Action</span>
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </Widget>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        //products: getProductsByCategoryId(state),
        products: state.products.data,
        categories: state.products.categories,
        curCategoryId: state.products.curCategoryId,
        merchant: state.auth.merchant,
        isReceiving: state.products.isReceiving,
        isDeleting: state.products.isDeleting,
        idToDelete: state.products.idToDelete,
    };
}

export default withRouter(connect(mapStateToProps)(ProdList));


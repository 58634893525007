// const hostApi = process.env.NODE_ENV === "development" ? "http://localhost" : "https://sing-generator-node.herokuapp.com";
// const portApi = process.env.NODE_ENV === "development" ? 8080 : "";
// const baseURLApi = `${hostApi}${portApi ? `:${portApi}` : ``}/api`;
// const redirectUrl = process.env.NODE_ENV === "development" ? "http://localhost:3000/sing-app-react" : "https://demo.flatlogic.com/sing-app-react";

const hostApi = process.env.NODE_ENV === "development" ? "https://posapi.rebelity.com" : "https://posapi.rebelity.com";
const portApi = process.env.NODE_ENV === "development" ? "" : "";
const baseURLApi = `${hostApi}${portApi ? `:${portApi}` : ``}`;


export default {
  hostApi,
  portApi,
  storageURL: "https://rebelity-pos-images.s3.amazonaws.com",
  baseURLApi,
  auth: {
    email: 'darkwat2',
    password: 'Jenifek1'
  },
  app: {
    colors: {
      dark: "#002B49",
      light: "#FFFFFF",
      sea: "#004472",
      sky: "#E9EBEF",
      wave: "#D1E7F6",
      rain: "#CCDDE9",
      middle: "#D7DFE6",
      black: "#13191D",
      salat: "#21AE8C",
    },
  }
};
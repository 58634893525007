import {
  RECEIVING_STATIONS,
  RECEIVED_STATIONS,
  FAILURE_STATIONS,
  UPDATING_STATIONS,

  RECEIVING_PICTURES,
  RECEIVED_PICTURES,
  FAILURE_PICTURES,
  UPDATING_PICTURES,
  DELETING_PICTURES,
  
  RECEIVING_VIDEOS,
  RECEIVED_VIDEOS,
  FAILURE_VIDEOS,
  UPDATING_VIDEOS,
  DELETING_VIDEOS,
  
  RECEIVING_WEBSITES,
  RECEIVED_WEBSITES,
  FAILURE_WEBSITES,
  UPDATING_WEBSITES,
  DELETING_WEBSITES,

  RECEIVING_STATION_SETTINGS,
  RECEIVED_STATION_SETTINGS,
  FAILURE_STATION_SETTINGS,

  RECEIVING_STORE_DETAILS,
  UPDATING_STORE_DETAILS,
  RECEIVED_STORE_DETAILS,
  FAILURE_STORE_DETAILS,

  UPDATING_PRODUCT_DEFAULTS,
  RECEIVED_PRODUCT_DEFAULTS,
  FAILURE_PRODUCT_DEFAULTS
} from '../actions/settings';

import { mockStoreDetails, mockProdDefaults } from '../model/mockStoreDetails';

const defaultState = {
    stations: [],
    pictures: [],
    websites: [],
    videos: [],
    storeDetails: mockStoreDetails,
    states: [],
    productDefaults: mockProdDefaults,
    isReceiving: false,
    isUpdating: false,
    isDeleting: false,
    idToDelete: null,
    error: ""
};

export default function settingsReducer(state = defaultState, action) {
    switch (action.type) {
        case RECEIVED_STATIONS:
            return Object.assign({}, state, {
                stations: action.payload.stations,
                isReceiving: false,
                isUpdating: false,
                isDeleting: false
            });
        case RECEIVED_PICTURES:
            return Object.assign({}, state, {
                pictures: action.payload.pictures,
                isReceiving: false,
                isUpdating: false,
                isDeleting: false
            });
        case RECEIVED_VIDEOS:
            return Object.assign({}, state, {
                videos: action.payload.videos,
                isReceiving: false,
                isUpdating: false,
                isDeleting: false
            });
        case RECEIVED_WEBSITES:
            return Object.assign({}, state, {
                websites: action.payload.websites,
                isReceiving: false,
                isUpdating: false,
                isDeleting: false
            });
        case RECEIVED_STATION_SETTINGS:
            return Object.assign({}, state, {
                websites: action.payload.websites,
                videos: action.payload.videos,
                pictures: action.payload.pictures,
                isReceiving: false,
                isUpdating: false,
                isDeleting: false
            });
        case RECEIVED_STORE_DETAILS:
            return Object.assign({}, state, {
                storeDetails: action.payload.storeDetails,
                states: action.payload.states,
                isReceiving: false,
                isUpdating: false,
                isDeleting: false
            });
        case RECEIVED_PRODUCT_DEFAULTS: {
            return Object.assign({}, state, {
                productDefaults: action.payload.productDefaults,
                isReceiving: false,
                isUpdating: false,
                isDeleting: false
            });
        }
        case RECEIVING_STATIONS:
        case RECEIVING_PICTURES:
        case RECEIVING_VIDEOS:
        case RECEIVING_WEBSITES:
        case RECEIVING_STATION_SETTINGS:
        case RECEIVING_STORE_DETAILS:
            return Object.assign({}, state, {
                isReceiving: true
            });
        case UPDATING_STATIONS:
        case UPDATING_PICTURES:
        case UPDATING_VIDEOS:
        case UPDATING_WEBSITES:
        case UPDATING_STORE_DETAILS:
        case UPDATING_PRODUCT_DEFAULTS:
            return Object.assign({}, state, {
                isUpdating: true
            });
        case DELETING_PICTURES:
        case DELETING_VIDEOS:
        case DELETING_WEBSITES:
            return Object.assign({}, state, {
                isDeleting: true
            });
        case FAILURE_STATIONS:
        case FAILURE_PICTURES:
        case FAILURE_VIDEOS:
        case FAILURE_WEBSITES:
        case FAILURE_STATION_SETTINGS:
        case FAILURE_STORE_DETAILS:
        case FAILURE_PRODUCT_DEFAULTS:
            return Object.assign({}, state, {
                error: action.payload,
                isReceiving: false,
                isUpdating: false,
                isDeleting: false
            });
        default:
            return state;
    }
}

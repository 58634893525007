import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Alert } from 'reactstrap';

import { createProductDefaults, updateProductDefaults } from "../../../../actions/settings";
import { mockFeeOptions } from '../../../../model/mockProduct';
import s from "./DefaultProduct.module.scss";

const DefaultProduct = ({categories, storeDetails, productDefaults}) => {
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [prodDefaults, setProdDefaults] = useState(productDefaults);
  const [categoryList, setCategories] = useState([]);

  useEffect(() => {
    setCategories(categories);
  }, [categories]);

  useEffect(() => {
    setProdDefaults(productDefaults);
  }, [productDefaults]);

  const onChangeProdName = (e) => {
    setProdDefaults({
      ...prodDefaults,
      CustomName: e.target.value
    });
  };

  const onChangeCategory = (e) => {
    setProdDefaults({
      ...prodDefaults,
      Category: e.target.value
    });
  };

  const onChangeFeeOption = (e) => {
    setProdDefaults({
      ...prodDefaults,
      FeeOption: e.target.value
    });
  };

  const onChangeTaxRate = (e) => {
    setProdDefaults({
      ...prodDefaults,
      TaxRate: e.target.value
    });
  };

  const onClickSave = (e) => {
    e.preventDefault();

    if (prodDefaults.ProductDefaultId == 0 ) {
      const data = {
        ...prodDefaults,
        MerchantId: storeDetails.MerchantId
      };

      dispatch(createProductDefaults(data));
    } else {
      const data = {
        ...prodDefaults,
        MerchantId: storeDetails.MerchantId
      };

      dispatch(updateProductDefaults(data));
    }
  }
  
  console.log('prodDefaults', prodDefaults);
  return (
    <div className={`bg-second p-4 ${s.card}`}>
      <h3 className="mt-0 mb-5 text-white">Default Product Details</h3>
      <form>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label>Default Item Name<i className={`${s.description}`}> ( Used for Custom Charges) </i></label>
                    <input type="text" className="form-control" placeholder="" required="" value={prodDefaults.CustomName} onChange={onChangeProdName} />
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label>Default Category</label>
                    <select className={`form-control ${s.select}`} value={prodDefaults.Category} onChange={onChangeCategory}>
                      {categoryList.map((category, i) => 
                          <option value={category.CategoryId} key={category.CategoryId}>{category.Name}</option>
                      )}
                    {/* {states.map((st, i) => 
                        <option value={st.value} key={st.value}>{st.text}</option>
                    )} */}
                    </select>
                </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                  <label>Default Card Fee Options</label>
                  <select className={`form-control ${s.select}`} value={prodDefaults.FeeOption} onChange={onChangeFeeOption}>
                  {mockFeeOptions.map((fee, i) => 
                      <option value={fee.Id} key={fee.Id}>{fee.Name}</option>
                  )}
                  </select>
              </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
                <label>Default Tax Rate %</label>
                <input type="number" className="form-control" placeholder="" required="" value={prodDefaults.TaxRate} onChange={onChangeTaxRate} />
            </div>
          </div>
        </div>
        
        <div className="row alert-container">
        {
            error && (
                <Alert className="alert-sm" color="danger">
                    {error}
                </Alert>
            )
        }
        </div>
        <button type="submit" className="btn pt-2 btn-block" onClick={onClickSave}>Save Product Defaults</button>
    </form>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    categories: state.products.categories,
    storeDetails: state.settings.storeDetails,
    productDefaults: state.settings.productDefaults
  };
}

export default withRouter(connect(mapStateToProps)(DefaultProduct));

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import {
    BootstrapTable,
    TableHeaderColumn,
} from 'react-bootstrap-table';
import { connect } from 'react-redux';

import { Popover, PopoverBody, PopoverHeader, Alert } from 'reactstrap';

import {
    Button,
    ButtonToolbar,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";

import { getProductsRequest, deleteProductRequest, setCategoryId, createCategoryRequest, updateCategoryRequest, deleteCateogryRequest } from '../../../actions/products'
import Loader from '../../../components/Loader';
import Widget from '../../../components/Widget';
import s from './Categories.module.scss';
import { mockCategory, mockPrinters } from '../../../model/mockCategory';

class Categories extends React.Component {
    
    static propTypes = {
        products: PropTypes.array,
        dispatch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        products: []
    };

    state = {
        popovers: {},
        promoAlert: false,
        isAddModal: false,
        curCategory: mockCategory,
        picFile: null,
        tempPicture: "",
        categoryError: "",
    };

    constructor() {
        super();
        this.apiFormatter = this.apiFormatter.bind(this);
        this.changeName = this.changeName.bind(this);
        this.changePrinter = this.changePrinter.bind(this);
        this.changePicture = this.changePicture.bind(this);
    }

    componentDidMount() {
        if (this.props.products.length < 1) {
            this.props.dispatch(getProductsRequest(this.props.merchant.MerchantId));
        }
    }

    changeName(event) {
        var curCategory = this.state.curCategory;
        curCategory.Name = event.target.value;
        this.setState({ curCategory: curCategory });
    }

    changePrinter(event) {
        var curCategory = this.state.curCategory;
        curCategory.PrinterId = event.target.value;
        this.setState({ curCategory: curCategory });
    }

    changePicture(event) {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            
            this.setState({
              tempPicture: URL.createObjectURL(file),
              picFile: file
            });
        } else {
            this.setState({picFile: null, tempPicture: ""});
        }
    }

    showPromoAlert = () => {
        this.setState({promoAlert: true});
    }

    showAddModal = () => {
        this.setState({isAddModal: true});
    }

    hideAddModal = (e) => {
        e.preventDefault();
        this.setState({isAddModal: false});
    }

    imageFormatter(cell) {
        return (
            <img src={cell} alt="..." className={s.image} title="image"/>
        )
    }

    onClickTitle = (id) => {
        this.props.dispatch(setCategoryId(id));
    }

    getProductsByCategoryId = (id) => {
        let products = this.props.products.filter(function( p ) {
            return p.CategoryId == id;
        });

        return products;
    }
    
    itemsFormatter = (cell) => {
        if (cell) {
            let products = this.getProductsByCategoryId(cell);
            return products.length;
        } else {
            return ""
        }
    }

    titleFormatter =(cell, row) => {
        return cell ? (
            <Link to={'/app/products/prod_list'} onClick={() => this.onClickTitle(row.CategoryId)}>
               {cell}
            </Link>
        ) : ""
    }

    editCategory = (id) => {
        const category = this.getCategoryById(id);
        this.setState({isAddModal: true, curCategory: category});
    }

    getCategoryById = (id) => {
        const {categories} = this.props;
        var data = categories.filter(function( c ) {
            return c.CategoryId == id;
        });

        var category = {... mockCategory};
        if (data.length > 0) {
            category = {... data[0]};
        } 
        return category;
    }

    deleteCategory = (id) => {
        this.props.dispatch(deleteCateogryRequest(id))
    }

    togglePopover = (id) => {
        let newState = {...this.state};
        if (!this.state.popovers[id]) {
            newState.popovers[id] = true;
        } else {
            newState.popovers[id] = !newState.popovers[id];
        }
        this.setState(newState);
    }

    apiFormatter = (cell, row) => {
        return (
            <ButtonToolbar className="">
                <button id={'popoverDelete_' + row.CategoryId} className="btn">
                    {this.props.isDeleting && this.props.idToDelete === row.CategoryId ? <Loader size={14}/> :
                        <div><i className="zmdi zmdi-delete"></i></div>
                    }
                </button>
                <button className="btn" onClick={()=> this.editCategory(row.CategoryId)}>
                    <div><i className="zmdi zmdi-edit"></i></div>
                </button>
                
                <Popover className="popover-danger" target={'popoverDelete_' + row.CategoryId} placement="top" isOpen={this.state.popovers[row.CategoryId]}
                         toggle={()=>{this.togglePopover(row.CategoryId)}}
                >
                    <PopoverHeader className="px-5">Are you sure?</PopoverHeader>
                    <PopoverBody className="px-5 d-flex justify-content-center">
                        <ButtonToolbar>
                            <Button color="success" size="xs" onClick={() => {this.deleteCategory(row.CategoryId)}}>
                                Yes
                            </Button>
                            <Button color="danger" size="xs" onClick={() => {this.togglePopover(row.CategoryId)}}>
                                No
                            </Button>
                        </ButtonToolbar>
                    </PopoverBody>
                </Popover>
            </ButtonToolbar>
        )
    }

    renderSizePerPageDropDown = (props) => {
        const limits = [];
        props.sizePerPageList.forEach((limit) => {
            limits.push(<DropdownItem key={limit}
                                      onClick={() => props.changeSizePerPage(limit)}>{limit}</DropdownItem>);
        });

        return (
            <Dropdown isOpen={props.open} toggle={props.toggleDropDown}>
                <DropdownToggle color="default" caret>
                    {props.currSizePerPage}
                </DropdownToggle>
                <DropdownMenu>
                    {limits}
                </DropdownMenu>
            </Dropdown>
        );
    };

    createNewCategory = (e) => {
        e.preventDefault();

        const {curCategory, picFile} = this.state;

        var categoryError = "";
        if (curCategory.Name == "") {
            categoryError = "Please input name";
        } 

        if (categoryError == "") {
            if (curCategory.CategoryId == 0) {
                this.props.dispatch(createCategoryRequest({
                    curCategory,
                    picFile
                }));
            } else {
                this.props.dispatch(updateCategoryRequest({
                    curCategory,
                    picFile
                }));
            }
            this.setState({isAddModal: false});
        } else {
            this.setState({categoryError: categoryError});
        }
        //this.props.history.push('/app/ecommerce/management/create');
    }

    render() {
        const options = {
            sizePerPage: 10,
            paginationSize: 3,
            sizePerPageDropDown: this.renderSizePerPageDropDown,
        };

        const {isAddModal, curCategory, tempPicture, categoryError} = this.state;

        return (
            <div className="category_list">
                <div className="tab_header">
                    <h1 className="d-flex">
                        <span className="d-block">Categories</span>
                        <button type="button" className="btn" onClick={() => this.editCategory(0)}>Add New</button>
                    </h1>
                    {/* <form className="search_box">
                        <div className="form-group d-flex">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><i className="zmdi zmdi-search"></i></div>
                            </div>
                            <input type="text" className="form-control" placeholder="Search"/>
                        </div>
                    </form> */}
                </div>
                <Widget>
                    <div className="order_list">
                        <BootstrapTable data={this.props.categories} tableHeaderClass='list_header' bordered={ false } striped={true} version="4" pagination options={options} 
                                        tableContainerClass={`table-striped ${s.bootstrapTable}`} search>
                            <TableHeaderColumn dataField="Picture" dataFormat={this.imageFormatter} className="width-5p"
                                            columnClassName="width-5p">
                                <span className="fs-sm">Image</span>
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="Name" dataFormat={this.titleFormatter} className="width-15p"
                                            columnClassName="width-15p">
                                <span className="fs-sm">Categoriy Name</span>
                            </TableHeaderColumn>
                            {window.innerWidth >= 768 && (
                                <TableHeaderColumn dataField="CategoryId" className="width-15p"
                                columnClassName="width-15p" dataFormat={this.itemsFormatter}>
                                    <span className="fs-sm">Items In Category</span>
                                </TableHeaderColumn>
                            )}
                            {window.innerWidth >= 768 && (
                                <TableHeaderColumn dataField="printer" className="width-10p"
                                columnClassName="width-10p">
                                    <span className="fs-sm">Printer</span>
                                </TableHeaderColumn>
                            )}
                            <TableHeaderColumn dataField="CategoryId" isKey={true}  dataFormat={this.apiFormatter} className="width-10p"
                                            columnClassName="width-10p">
                                <span className="fs-sm">Action</span>
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>

                    <Drawer anchor="right" open={isAddModal} onClose={this.hideAddModal}>
                        <div className="add_category_model add_expenses receipt_model px-0" 
                            id="add_expenses" tabIndex="-1" role="dialog" aria-labelledby="receipt_modelTitle" aria-modal="true">
                            <div className="modal-dialog modal-dialog-scrollable" role="document">
                                <div className="modal-content">
                                    <div className="modal-header px-0">
                                        <h2 className="col-10 mx-auto">{curCategory.CategoryId == 0 ? "Add Category": "Edit Category"}</h2>
                                    </div>
                                    <div className="row alert-container">
                                    {
                                        categoryError && (
                                            <Alert className="alert-sm" color="danger">
                                                {categoryError}
                                            </Alert>
                                        )
                                    }
                                    </div>
                                    <div className="modal-body p-0">
                                        <form>
                                            <div className="col-10 mx-auto form_container">
                                                <div className="row pb-5">
                                                    <div className="upload-box mt-1 mb-4 mx-auto">
                                                        <label htmlFor="img" className="img m-0 active">
                                                            <i className="zmdi zmdi-image-alt"></i>
                                                            <input id="img" type="file" onChange={this.changePicture}/>
                                                            <span>Upload icon</span>
                                                        </label>
                                                    </div>
                                                    {
                                                        (tempPicture == "" || tempPicture == null) && (curCategory.Picture == "" || curCategory.Picture == null) ? (
                                                            <div className="col-12 col-lg-7 col-md-6 col-sm-12 pt-2">
                                                                <div className="d-flex info">
                                                                    <i className="zmdi zmdi-info-outline d-block text-white"></i>
                                                                    <p>Icon Should have in 1:1 ratio for better viewing experience.</p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="col-12 col-lg-7 col-md-6 col-sm-12 pt-2">
                                                                {
                                                                    tempPicture != "" ? (
                                                                        <img src={tempPicture} alt="..." className={s.image} title="image"/>
                                                                    ) : (
                                                                        <img src={curCategory.Picture} alt="..." className={s.image} title="image"/>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    
                                                </div>
                                                <div className="form-group">
                                                    <label>Category Name</label>
                                                    <input type="text" className="form-control" value={curCategory.Name} onChange={this.changeName}/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Select Printer</label>
                                                    <select className="form-control" value={curCategory.PrinterId} onChange={this.changePrinter}>
                                                    {mockPrinters.map((printer, i) => 
                                                        <option value={printer.Id} key={printer.Id}>{printer.Name}</option>
                                                    )}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="modal-footer">
                                                <div className="row no-gutters w-100">
                                                    <div className="col-6"> <button type="file" className="btn Cencel" onClick={(e) => this.hideAddModal(e)}>Cancel</button></div>
                                                    <div className="col-6"> <button type="file" className="btn" onClick={(e) => this.createNewCategory(e)}>{curCategory.CategoryId == 0 ? "Add": "Update"}</button></div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                </Widget>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        products: state.products.data,
        categories: state.products.categories,
        merchant: state.auth.merchant,
        isReceiving: state.products.isReceiving,
        isDeleting: state.products.isDeleting,
        idToDelete: state.products.idToDelete,
    };
}

export default withRouter(connect(mapStateToProps)(Categories));


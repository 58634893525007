import axios from 'axios';
import qs from 'qs';
//import Errors from '../components/FormItems/error/errors';

export const AUTH_FAILURE = 'AUTH_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const PASSWORD_RESET_EMAIL_REQUEST = 'PASSWORD_RESET_EMAIL_REQUEST';
export const PASSWORD_RESET_EMAIL_SUCCESS = 'PASSWORD_RESET_EMAIL_SUCCESS';
export const AUTH_INIT_SUCCESS = 'AUTH_INIT_SUCCESS';
export const AUTH_INIT_ERROR = 'AUTH_INIT_ERROR';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

async function getMerchant() {
  const response = await axios.get('/api/merchant');
  return response.data;
}

export function authError(payload) {
  return {
    type: AUTH_FAILURE,
    payload,
  };
}

export function doInit() {
  return async (dispatch) => {
    let currentUser = null;
    let merchant = null;
    try {
      let token = localStorage.getItem('token');
      if (token) {
        merchant = await getMerchant();

        let user = localStorage.getItem('user');
        currentUser = JSON.parse(user);
        console.log("merchant");
        console.log(merchant);
      }
      dispatch({
        type: AUTH_INIT_SUCCESS,
        payload: {
          currentUser,
          merchant
        },
      });
    } catch (error) {
      //Errors.handle(error);
      console.log(error);

      dispatch({
        type: AUTH_INIT_ERROR,
        payload: error,
      });
    }
  }
}

export function logoutUser() {
    return (dispatch) => {
        dispatch({
          type: LOGOUT_REQUEST,
        });
        console.log("logout");
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        axios.defaults.headers.common['Authorization'] = "";
        dispatch({
          type: LOGOUT_SUCCESS,
        });
    };
}

export function receiveToken(data) {
    return (dispatch) => {

        console.log(data);

        let token = data.access_token;
        let issued = new Date(data['.issued']).getTime() / 1000;
        let user = {
          user_name: data.userName,
          expires_in: data.expires_in,
          issued: issued
        }
        
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        dispatch({
          type: LOGIN_SUCCESS
        });
       // dispatch(push('/admin'));
    }
}

export function loginUser(creds) {
    return (dispatch) => {
      dispatch({
        type: LOGIN_REQUEST,
      });
      
      if (creds.username.length > 0 && creds.password.length > 0) {
        axios.post("/token", qs.stringify(creds)).then(res => {
          console.log(res);
          dispatch(receiveToken(res.data));
          dispatch(doInit());
        //  dispatch(push('/admin'));
        }).catch(err => {
          console.log(err);
          dispatch(authError('Network is wrong. Try again'));
        })
      } else {
        dispatch(authError('Something was wrong. Try again'));
      }
    };
}
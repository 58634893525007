import React from 'react';
import PropTypes from 'prop-types';
import s from './Widget.module.scss';
import classNames from 'classnames';
import Loader from '../Loader';
import AnimateHeight from 'react-animate-height';

class Widget extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    bodyClass: PropTypes.string,
    fetchingData: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    children: [],
    bodyClass: '',
    fetchingData: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      hideWidget: false,
      height:  props.collapsed ? 0 : 'auto',
      reloading: false,
    }

  }

  render() {
  
    const {
      className,
      children,
      bodyClass,
      fetchingData,
      ...attributes
    } = this.props;
    
    const {
      reloading,
      height,
      hideWidget
    } = this.state;
    
    return (
      <React.Fragment>
        <section
          style={{display: hideWidget ? 'none' : ''}}  
          className={
            classNames('widget', s.widget, className, (reloading || fetchingData) ? s.reloading : '')
          } {...attributes}
          >
          <AnimateHeight
            duration={ 500 }
            height={ height }
          >
            {reloading || fetchingData ?  <Loader className={s.widgetLoader} size={80}/> : children}
          </AnimateHeight>
        </section>
      </React.Fragment>
    );
  }
}

export default Widget;

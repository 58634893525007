import axios from 'axios';
import { toast } from 'react-toastify';
import mock from "../pages/products/backendMock";
import config from "../config";
import { receivedProductDefaults } from './settings';

export const RECEIVED_CATEGORIES = 'RECEIVED_CATEGORIES';
export const RECEIVED_PRODUCTS = 'RECEIVED_PRODUCTS';
export const RECEIVING_PRODUCTS = 'RECEIVING_PRODUCTS';
export const RECEIVED_PRODUCT = 'RECEIVED_PRODUCT';
export const RECEIVING_PRODUCT = 'RECEIVING_PRODUCT';
export const CREATED_PRODUCT = 'CREATED_PRODUCT';
export const CREATED_VARIATION = 'CREATED_VARIATION';
export const UPDATED_VARIATION = 'UPDATED_VARIATION';
export const CREATED_OPTION = 'CREATED_OPTION';
export const UPDATED_OPTION = 'UPDATED_OPTION';
export const DELETED_OPTION = 'DELETED_OPTION';
export const UPDATED_PRODUCT = 'UPDATED_PRODUCT';
export const UPDATING_PRODUCT = 'UPDATING_PRODUCT';
export const DELETED_PRODUCT = 'DELETED_PRODUCT';
export const DELETED_VARIATION = 'DELETED_VARIATION';
export const DELETING_PRODUCT = 'DELETING_PRODUCT';
export const RECEIVED_IMAGES = 'RECEIVED_IMAGES';
export const SET_CATEGORY_ID = 'SET_CATEGORY_ID';
export const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT';
export const FAILURE_PRODUCT = 'FAILURE_PRODUCT';
export const CREATED_CATEGORY = 'CREATED_CATEGORY';
export const UPDATED_CATEGORY = 'UPDATED_CATEGORY';
export const UPDATING_CATEGORY = 'UPDATING_CATEGORY';
export const DELETED_CATEGORY = 'DELETED_CATEGORY';
export const DELETING_CATEGORY = 'DELETING_CATEGORY';
export const FAILURE_CATEOGRY = 'FAILURE_CATEOGRY';

export function setCategoryId(id) {
    return (dispatch) => {
        dispatch({
            type: SET_CATEGORY_ID,
            payload: id
        });
    };
}

export function setCurProductId(id) {
    return (dispatch) => {
        dispatch(updatingProduct());
        dispatch({
            type: SET_CURRENT_PRODUCT,
            payload: id
        });
    };
}

async function getCategories(merchantId) {
    const response = await axios.get(`/api/products/categories/${merchantId}`);
    return response.data.categories;
}

async function getSingleProduct(productId) {
    const response = await axios.get(`/api/products/single/${productId}`);
    console.log("siproduct");
                console.log(response);
    return response.data.product;
}

async function createProdPicture(picFile) {
    console.log("picFile");
    console.log(picFile);

    const formData = new FormData();
    formData.append('file',picFile)
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await axios.post("/media/uploadproductpicture", formData,config)

    return response.data.pictureFile;
}

async function updateProdPicture(ProductId, picFile) {
    console.log("picFile");
    console.log(picFile);

    const formData = new FormData();
    formData.append('file',picFile)
    formData.append('productId',ProductId)
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await axios.put("/media/updateproductpicture", formData,config)

    return response.data.picture;
}

async function getProductDefaults(merchantId) {
    const response = await axios.get(`/api/product/defaults/merchant/${merchantId}`);
    return response.data;
}

export function getProductsRequest(merchantId) {
    return async (dispatch) => {
        dispatch(receivingProducts());
        let categories = await getCategories(merchantId);

        axios.get(`/api/products/${merchantId}`).then(async res => {
            let products = res.data.products;
            let payload = {
                categories,
                products
            }
            
            const prodDefaults = await getProductDefaults(merchantId);
            if (prodDefaults.status == 'Ok') {
                let prod_payload = {
                    productDefaults: prodDefaults.defaults
                };
                dispatch(receivedProductDefaults(prod_payload));
            }
            
            dispatch(receiveProducts(payload));
        })
    };
}

export function loadProductRequest(id) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) {
            dispatch(receiveProduct(mock.find(arr => arr.id === id)));
        }

        else {
            dispatch(receivingProduct());
            axios.get('/products/' + id).then(res => {
                dispatch(receiveProduct(res.data));
            })
        }
    };
}

export function updateProductRequest(payload) {
    return async (dispatch) => {
        dispatch(updatingProduct());

        if (payload.picFile != null) {
            let picFileName = await updateProdPicture(payload.curProduct.ProductId, payload.picFile);
            console.log("prodPicture");
            console.log(picFileName);
            payload.curProduct.Picture = picFileName;
        }
        console.log("payload.curProduct");
        console.log(payload.curProduct);
        axios.put('/api/product/update', payload.curProduct).then(res => {
            console.log(res);
            if (res.data.status == "Error") {
                toast.error(res.data.message);
                dispatch(failtureProduct(res.data.message));
            } else {
                console.log(payload.curProduct);
                dispatch(updateProduct(payload.curProduct));
                payload.history.push('/admin/products/prod_list');
                toast.success("Product has been Updated!");
            }
        })
    };
}

export function updateInventoryRequest(product) {
    return async (dispatch) => {
        dispatch(updatingProduct());

        axios.put(`/api/product/inventory/${product.ProductId}/${product.Inventory}`).then(res => {
            console.log(res);
            if (res.data.status == "Error") {
                toast.error(res.data.message);
                dispatch(failtureProduct(res.data.message));
            } else {
                dispatch(updateProduct(product));
                toast.success("Inventory has been Updated!");
            }
        })
    };
}

function initProduct(product) {
    product.ProductId = 0;
    product.Name = "";
    product.CategoryId = 2;
    product.Description = "";
    product.SKU = "";
    product.Barcode = "";
    product.Price = 0;
    product.FeeOption = 1;
    product.TaxRate = 0;
    product.Picture = null;
    product.PictureFile = null;
    product.Inventory = 0;
    product.Variations = [];
    product.Options = [];
}

export function createProductRequest(payload) {
    return async (dispatch) => {
        console.log("create payload");
        console.log(payload);

        dispatch(updatingProduct());

        let picFileName = await createProdPicture(payload.picFile);
        //const picPath = `${config.storageURL}/products/${payload.merchantId}/${picFileName}`;
        payload.curProduct.Picture = picFileName;
        
        axios.post('/api/product/add', payload.curProduct).then(async res => {
            if (res.data.status == "Error") {
                toast.error(res.data.message);
                dispatch(failtureProduct(res.data.message));
            } else {
                const product = await getSingleProduct(res.data.product.ProductId);
                // console.log("sss");
                // console.log(sss);
                // var product = res.data.product;
                // if (picFileName != null) {
                //     product.Picture = picPath;
                // }
                console.log("added product");
                console.log(product);
                dispatch(createProduct(product));
                payload.history.push('/admin/products/prod_list');
                toast.success("Product has been Created!");
                //initProduct(payload.curProduct);
            }
        })
    };
}

export function createVariationRequest(payload) {
    return async (dispatch) => {
        console.log(payload);

        dispatch(updatingProduct());

        var productId =  payload.productId;
        var variation = payload.variation;

        axios.post('/api/product/add/variation/' + productId, variation).then(res => {
            console.log(res);
            if (res.data.status == "Error") {
                toast.error(res.data.message);
                dispatch(failtureProduct(res.data.message));
            } else {
                variation.VariationId = res.data.variationId;
                dispatch(createVariation({
                    ProductId: productId,
                    variation
                }));
                toast.success("Variation has been Created!");
            }
        })
    };
}

export function updateVariationRequest(payload) {
    return async (dispatch) => {
        console.log(payload);

        dispatch(updatingProduct());

        var productId =  payload.productId;
        var variation = payload.variation;

        axios.put('/api/product/update/variation', variation).then(res => {
            console.log(res);
            if (res.data.status == "Error") {
                toast.error(res.data.message);
                dispatch(failtureProduct(res.data.message));
            } else {
                dispatch(updateVariation({
                    ProductId: productId,
                    variation
                }));
                toast.success("Variation has been Updated!");
            }
        })
    };
}

export function deleteVariationRequest(payload) {
    return (dispatch) => {
        
        dispatch(deletingProduct(payload.productId));
        axios.delete('/api/product/delete/variation/' +payload.variationId).then(res => {
            if (res.data.status == "Error") {
                toast.error(res.data.message);
                dispatch(failtureProduct(res.data.message));
            } else {
                dispatch(deleteVariation(payload));
                toast.success("Variation has been Deleted!");
            }
        })
    };
}


export function createOptionRequest(payload) {
    return async (dispatch) => {
        console.log(payload);

        dispatch(updatingProduct());

        var productId =  payload.productId;
        var option = payload.option;

        axios.post('/api/product/add/option/' + productId, option).then(res => {
            console.log(res);
            if (res.data.status == "Error") {
                toast.error(res.data.message);
                dispatch(failtureProduct(res.data.message));
            } else {
                option.OptionId = res.data.optionId;
                dispatch(createOption({
                    ProductId: productId,
                    option
                }));
                toast.success("Option has been Created!");
            }
        })
    };
}

export function updateOptionRequest(payload) {
    return async (dispatch) => {
        console.log(payload);

        dispatch(updatingProduct());

        var productId =  payload.productId;
        var option = payload.option;

        axios.put('/api/product/update/option', option).then(res => {
            console.log(res);
            if (res.data.status == "Error") {
                toast.error(res.data.message);
                dispatch(failtureProduct(res.data.message));
            } else {
                dispatch(updateOption({
                    ProductId: productId,
                    option
                }));
                toast.success("Option has been Updated!");
            }
        })
    };
}

export function deleteOptionRequest(payload) {
    return async (dispatch) => {
        console.log(payload);

        dispatch(deletingProduct(payload.productId));
        axios.delete('/api/product/delete/option/' +payload.optionId).then(res => {
            if (res.data.status == "Error") {
                toast.error(res.data.message);
                dispatch(failtureProduct(res.data.message));
            } else {
                dispatch(deleteOption(payload));
                toast.success("Option has been Deleted!");
            }
        })
    };
}

export function deleteProductRequest(id) {
    return (dispatch) => {
        
        dispatch(deletingProduct(id));
        axios.delete('/api/product/delete/' +id).then(res => {
            console.log("delete product");
            console.log(id);
            console.log(res);
            if (res.data.status == "Error") {
                toast.error(res.data.message);
                dispatch(failtureProduct(res.data.message));
            } else {
                dispatch(deleteProduct(id));
                toast.success("Product has been Deleted!");
            }
        })
    };
}

async function createCategoryPicture(picFile) {
    console.log("picFile");
    console.log(picFile);

    const formData = new FormData();
    formData.append('file',picFile)
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await axios.post("/media/uploadproductcategorypicture", formData,config)

    return response.data.pictureFile;
}

async function updateCategoryPicture(CategoryId, picFile) {
    console.log("picFile");
    console.log(picFile);

    console.log("CategoryId");
    console.log(CategoryId);

    const formData = new FormData();
    formData.append('file',picFile)
    formData.append('categoryId',CategoryId)
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    console.log("formData");
    console.log(formData);
    const response = await axios.put("/media/updateproductcategorypicture", formData,config)

    return response.data;
}

export function updateCategoryRequest(payload) {
    return async (dispatch) => {
        dispatch(updatingCategory());

        console.log("update cateogry");
        console.log(payload)
        if (payload.picFile != null) {
            let picFileName = await updateCategoryPicture(payload.curCategory.CategoryId, payload.picFile);
            console.log("categoryPicture");
            console.log(picFileName);
            //payload.curCategory.Picture = picFileName;
        }
        payload.curCategory.Picture = null;
        
        //const picPath = `${config.storageURL}/products/${payload.merchantId}/${picFileName}`;
        
        axios.put('/api/product/category/update', payload.curCategory).then(res => {
            console.log(res);
            if (res.data.status == "Error") {
                toast.error(res.data.message);
                dispatch(failtureCategory(res.data.message));
            } else {
                dispatch(updateCategory(res.data.category));
                toast.success("Category has been Updated!");
            }
        })
    };
}

export function deleteCateogryRequest(id) {
    return (dispatch) => {
        
        dispatch(deletingCategory(id));
        axios.delete('/api/product/category/delete/' +id).then(res => {
            if (res.data.status == "Error") {
                toast.error(res.data.message);
                dispatch(failtureCategory(res.data.message));
            } else {
                dispatch(deleteCategory(id));
                toast.success("Category has been Deleted!");
            }
        })
    };
}

export function createCategoryRequest(payload) {
    return async (dispatch) => {
        console.log(payload);

        dispatch(updatingCategory());

        let picFileName = await createCategoryPicture(payload.picFile);
        console.log("categoryPicture");
        console.log(picFileName);
        payload.curCategory.Picture = picFileName;

        axios.post('/api/product/category/add', payload.curCategory).then(res => {
            console.log(res);
            if (res.data.status == "Error") {
               toast.error(res.data.message);
               dispatch(failtureCategory(res.data.message));
            } else {
                // var product = res.data.product;
                // if (picFileName != null) {
                //     product.Picture = picPath;
                // }
                console.log("added category");
                console.log(res.data.category);

                dispatch(createCategory(res.data.category));
                toast.success("Category has been Created!");
            }
        })
    };
}

export function getProductsImagesRequest(payload) {
  return (dispatch) => {
    // We check if app runs with backend mode
    if (!config.isBackend) return;

    axios.get('/products/images-list').then(res => {
      dispatch(receiveProductImages(res.data));
      if (!payload.img && res.data.length) {
        dispatch(updateProduct({id: payload.id, img: res.data[0]}));
      }
    })
  };
}

export function receiveProductImages(payload) {
  return {
    type: RECEIVED_IMAGES,
    payload
  }
}

export function receiveCategories(payload) {
    console.log(payload);
    return {
        type: RECEIVED_CATEGORIES,
        payload
    }
}

export function receiveProducts(payload) {
    console.log(payload);
    return {
        type: RECEIVED_PRODUCTS,
        payload
    }
}

export function receivingProducts() {
    return {
        type: RECEIVING_PRODUCTS
    }
}

export function receiveProduct(payload) {
    return {
        type: RECEIVED_PRODUCT,
        payload
    }
}

export function receivingProduct() {
    return {
        type: RECEIVING_PRODUCT
    }
}

export function createProduct(payload) {
    return {
        type: CREATED_PRODUCT,
        payload
    }
}

export function createVariation(payload) {
    return {
        type: CREATED_VARIATION,
        payload
    }
}

export function updateVariation(payload) {
    return {
        type: UPDATED_VARIATION,
        payload
    }
}

export function createOption(payload) {
    console.log("create option payload");
    console.log(payload);

    return {
        type: CREATED_OPTION,
        payload
    }
}

export function updateOption(payload) {
    
    return {
        type: UPDATED_OPTION,
        payload
    }
}

export function deleteOption(payload) {
    
    return {
        type: DELETED_OPTION,
        payload
    }
}

export function updateProduct(payload) {
    return {
        type: UPDATED_PRODUCT,
        payload
    }
}

export function updatingProduct() {
    return {
        type: UPDATING_PRODUCT
    }
}

export function deleteProduct(payload) {
    return {
        type: DELETED_PRODUCT,
        payload
    }
}

export function deleteVariation(payload) {
    return {
        type: DELETED_VARIATION,
        payload
    }
}

export function deletingProduct(payload) {
    return {
        type: DELETING_PRODUCT,
        payload
    }
}

export function failtureProduct(payload) {
    return {
        type: FAILURE_PRODUCT,
        payload
    }
}

export function createCategory(payload) {
    return {
        type: CREATED_CATEGORY,
        payload
    }
}

export function updateCategory(payload) {
    return {
        type: UPDATED_CATEGORY,
        payload
    }
}

export function updatingCategory() {
    return {
        type: UPDATING_CATEGORY
    }
}

export function deleteCategory(payload) {
    return {
        type: DELETED_CATEGORY,
        payload
    }
}

export function deletingCategory(payload) {
    return {
        type: DELETING_CATEGORY,
        payload
    }
}

export function failtureCategory(payload) {
    return {
        type: FAILURE_CATEOGRY,
        payload
    }
}

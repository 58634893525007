export const mockStoreDetails = {
  MerchantId: 0,
  StoreName: "",
  StoreType: 0,
  Email: "",
  Telephone: "",
  UseClientAddress: false,
  Street: "",
  City: "",
  State: "",
  Zipcode: "",
  Description: "",
  ReceiptText: "",
  Picture: "",
  StoreImage:"",
  AutoStartWP: null,
  AutoCloseWP: null,
};

export const mockProdDefaults = {
  ProductDefaultId : 0,
  MerchantId: 0,
  CustomName: "",
  Category: 2,
  FeeOption: 1,
  TaxRate: 0
};

export const mockStoreTypes = [
  {
    Id: 0,
    Name: "Physical Store (Brick & Mortar)"
  },
  {
    Id: 1,
    Name: "Online"
  },
  {
    Id: 2,
    Name: "Both (Online and Physical Store)"
  }
]

export const mockStates = [
  {
    Abbreviation: "AL",
    Name: "Alabama"
  },
  {
    Abbreviation: "AK",
    Name: "Alaska"
  },
  {
    Abbreviation: "AZ",
    Name: "Arizona"
  },
  {
    Abbreviation: "AR",
    Name: "Arkansas"
  },
  {
    Abbreviation: "CA",
    Name: "California"
  },
  {
    Abbreviation: "CO",
    Name: "Colorado"
  },
  {
    Abbreviation: "CT",
    Name: "Connecticut"
  },
  {
    Abbreviation: "DE",
    Name: "Delaware"
  },
  {
    Abbreviation: "FL",
    Name: "Florida"
  },
  {
    Abbreviation: "GA",
    Name: "Georgia"
  },
  {
    Abbreviation: "HI",
    Name: "Hawaii"
  },
  {
    Abbreviation: "ID",
    Name: "Idaho"
  },
  {
    Abbreviation: "IL",
    Name: "Illinois"
  },
  {
    Abbreviation: "IN",
    Name: "Indiana"
  },
  {
    Abbreviation: "IA",
    Name: "Iowa"
  },
  {
    Abbreviation: "KS",
    Name: "Kansas"
  },
  {
    Abbreviation: "KY",
    Name: "Kentucky"
  },
  {
    Abbreviation: "LA",
    Name: "Louisiana"
  },
  {
    Abbreviation: "ME",
    Name: "Maine"
  },
  {
    Abbreviation: "MD",
    Name: "Maryland"
  },
  {
    Abbreviation: "MA",
    Name: "Massachusetts"
  },
  {
    Abbreviation: "MI",
    Name: "Michigan"
  },
  {
    Abbreviation: "MN",
    Name: "Minnesota"
  },
  {
    Abbreviation: "MS",
    Name: "Mississippi"
  },
  {
    Abbreviation: "MO",
    Name: "Missouri"
  },
  {
    Abbreviation: "MT",
    Name: "Montana"
  },
  {
    Abbreviation: "NE",
    Name: "Nebraska"
  },
  {
    Abbreviation: "NV",
    Name: "Nevada"
  },
  {
    Abbreviation: "NH",
    Name: "New Hampshire"
  },
  {
    Abbreviation: "NJ",
    Name: "New Jersey"
  },
  {
    Abbreviation: "NM",
    Name: "New Mexico"
  },
  {
    Abbreviation: "NY",
    Name: "New York"
  },
  {
    Abbreviation: "NC",
    Name: "North Carolina"
  },
  {
    Abbreviation: "ND",
    Name: "North Dakota"
  },
  {
    Abbreviation: "OH",
    Name: "Ohio"
  },
  {
    Abbreviation: "OK",
    Name: "Oklahoma"
  },
  {
    Abbreviation: "OR",
    Name: "Oregon"
  },
  {
    Abbreviation: "PA",
    Name: "Pennsylvania"
  },
  {
    Abbreviation: "RI",
    Name: "Rhode Island"
  },
  {
    Abbreviation: "SC",
    Name: "South Carolina"
  },
  {
    Abbreviation: "SD",
    Name: "South Dakota"
  },
  {
    Abbreviation: "TN",
    Name: "Tennessee"
  },
  {
    Abbreviation: "TX",
    Name: "Texas"
  },
  {
    Abbreviation: "UT",
    Name: "Utah"
  },
  {
    Abbreviation: "VT",
    Name: "Vermont"
  },
  {
    Abbreviation: "VA",
    Name: "Virginia"
  },
  {
    Abbreviation: "WA",
    Name: "Washington"
  },
  {
    Abbreviation: "WV",
    Name: "West Virginia"
  },
  {
    Abbreviation: "WI",
    Name: "Wisconsin"
  },
  {
    Abbreviation: "WY",
    Name: "Wyoming"
  }
]
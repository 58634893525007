import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Container, Alert, Button } from 'reactstrap';

import { setCategoryId } from '../../actions/products'

import ProdList from './ProdList/ProdList';
import Categories from './Categories/Categories';
import ProductEdit from './ProductEdit/ProductEdit';

import Loader from '../../components/Loader/Loader';

class Products extends React.Component {

    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    };

    onClickProducts = (e) => {
        this.props.dispatch(setCategoryId(0));
    }

    onClickCategories = (e) =>  {
        this.props.dispatch(setCategoryId(0));
    }

    render() {
        const { pathname } = this.props.location;

        const {isReceiving, isUpdating, isDeleting} = this.props;
        
        return (
            <div className="body_wrapper">
                {
                    this.props.errorMessage && (
                        <Alert className="alert-sm" color="danger">
                            {this.props.errorMessage}
                        </Alert>
                    )
                }
                <div className="left_sidebar">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <Link className={pathname.startsWith('/admin/products/prod_list') ? "nav-item nav-link active" : "nav-item nav-link"} to="/admin/products/prod_list" onClick={this.onClickProducts}>Products</Link>
                        <Link className={pathname.startsWith('/admin/products/categories') ? "nav-item nav-link active" : "nav-item nav-link"} to="/admin/products/categories" onClick={this.onClickCategories}>Categories</Link>
                    </div>
                </div>
                <div className="right_sidebar">
                    {
                        isReceiving || isUpdating || isDeleting ? (<Loader size={80}/>) : (<div></div>)
                    }
                    <TransitionGroup>
                        <CSSTransition
                        key={this.props.location.key}
                        classNames="fade"
                        timeout={600}
                        >
                        <Switch>
                            <Route path="/admin/products" exact render={() => <Redirect to="/admin/products/prod_list" />} />
                            <Route path="/admin/products/prod_list" exact component={ProdList} />
                            <Route path="/admin/products/prod_list/:id" exact component={ProductEdit} />
                            <Route path="/admin/products/prod_list/create" exact component={ProductEdit} />
                            <Route path="/admin/products/categories" exact component={Categories} />
                        </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isReceiving: state.products.isReceiving,
        isUpdating: state.products.isUpdating,
        isDeleting: state.products.isDeleting,
        isFetching: state.auth.isFetching,
        errorMessage: state.auth.errorMessage,
    };
}

export default withRouter(connect(mapStateToProps)(Products));


import React from 'react';
import PropTypes from 'prop-types';
import { withRouter} from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import {
    BootstrapTable,
    TableHeaderColumn,
} from 'react-bootstrap-table';
import { connect } from 'react-redux';

import { Popover, PopoverBody, PopoverHeader, Alert } from 'reactstrap';

import {
    Button,
    ButtonToolbar,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";

import s from './Users.module.scss';

import { setRoleId, getUsersRequest, deleteUserRequest, updateUserRequest, createUserRequest } from '../../actions/users'
import { mockUser } from '../../model/mockUser';

import Loader from '../../components/Loader';
import Widget from '../../components/Widget';

class Users extends React.Component {

    static propTypes = {
        users: PropTypes.array,
        dispatch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        users: []
    };

    state = {
        popovers: {},
        promoAlert: false,
        isAddModal: false,
        curUser: mockUser,
        selectedRole: 1,
        userError: ""
    };

    constructor(props) {
        super(props);

        this.changeUsername = this.changeUsername.bind(this);
        this.changePhone = this.changePhone.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changePin = this.changePin.bind(this);
        this.changeRole = this.changeRole.bind(this);
    }

    changeUsername(event) {
        var user = this.state.curUser;
        user.Username = event.target.value;
        this.setState({ curUser: user });
    }

    changePhone(event) {
        var user = this.state.curUser;
        user.Telephone = event.target.value;
        this.setState({ curUser: user });
    }

    changeEmail(event) {
        var user = this.state.curUser;
        user.Email = event.target.value;
        this.setState({ curUser: user });
    }

    changePin(event) {
        var user = this.state.curUser;
        user.Pin = event.target.value;
        this.setState({ curUser: user });
    }

    changeRole(event) {
        this.setState({ selectedRole: event.target.value });
    }

    componentDidMount() {
        if (this.props.users.length < 1) {
            this.props.dispatch(getUsersRequest(this.props.merchant.MerchantId));
        }
    }

    showPromoAlert = () => {
        this.setState({promoAlert: true});
    }

    showAddModal = () => {
        this.setState({isAddModal: true});
    }

    hideAddModal = (e) => {
        e.preventDefault();

        this.setState({...this.state, isAddModal: false});
    }

    onClickRole = (e, role_id) => {
        e.preventDefault();
    
        this.props.dispatch(setRoleId(role_id));
    }

    createNewUser = (e) => {
        e.preventDefault();

        const {curUser} = this.state;

        var userError = "";
        if (curUser.Username == "") {
            userError = "Please input name";
        } else if (curUser.Telephone == "") {
            userError = "Please input phone number";
        } else if (curUser.Email == "") {
            userError = "Please input email address";
        } else if (curUser.Pin == "") {
            userError = "Please input Pin number";
        } else if (curUser.Roles.length < 1) {
            userError = "Please add role";
        }

        if (userError == "") {
            if (curUser.UserId == 0) {
                this.props.dispatch(createUserRequest(curUser));
            } else {
                this.props.dispatch(updateUserRequest(curUser));
            }
            this.setState({isAddModal: false});
        } else {
            this.setState({userError: userError});
        }
    }

    editUser = (id) => {
        const {curRoleId} = this.props;
        
        const user = this.getUserById(id);
        this.setState({isAddModal: true, selectedRole: curRoleId, curUser: user});
    }

    getUserById = (id) => {
        const {users, merchant} = this.props;
        var data = users.filter(function( u ) {
            return u.UserId == id;
        });

        var user = {... mockUser};
        if (data.length > 0) {
            user = {... data[0]};
        } else {
            user.MerchantId = merchant.MerchantId;
        }
        return user;
    }

    deleteUser = (id) => {
        this.togglePopover(id);
        this.props.dispatch(deleteUserRequest(id));
    }

    addUserRole = (e) => {
        e.preventDefault();
        var user = this.state.curUser;
        var selectedRole = this.state.selectedRole * 1;

        var userRoles = user.Roles.filter(function( r ) {
            return r == selectedRole;
        });

        if (userRoles.length < 1) {
            user.Roles.push(selectedRole);
        }
        
        this.setState({ curUser: user });
    }

    deleteUserRole = (e) => {
        e.preventDefault();
        var user = this.state.curUser;
        var selectedRole = this.state.selectedRole;

        const userRoles = user.Roles.filter(function( r ) {
            return r != selectedRole;
        });

        user.Roles = userRoles;

        this.setState({ curUser: user });
    }

    togglePopover(id) {
        let newState = {...this.state};
        if (!this.state.popovers[id]) {
            newState.popovers[id] = true;
        } else {
            newState.popovers[id] = !newState.popovers[id];
        }
        this.setState(newState);
    }

    apiFormatter = (cell, row) => {
        return (
            <ButtonToolbar className="">
                <button id={'popoverDelete_' + row.UserId} className="btn">
                    {this.props.isDeleting && this.props.idToDelete === row.UserId ? <Loader size={14}/> :
                        <div><i className="zmdi zmdi-delete"></i></div>
                    }
                </button>
                <button className="btn" onClick={()=> this.editUser(row.UserId)}>
                    <div><i className="zmdi zmdi-edit"></i></div>
                </button>
                
                <Popover className="popover-danger" target={'popoverDelete_' + row.UserId} placement="top" isOpen={this.state.popovers[row.UserId]}
                         toggle={()=>{this.togglePopover(row.UserId)}}
                >
                    <PopoverHeader className="px-5">Are you sure?</PopoverHeader>
                    <PopoverBody className="px-5 d-flex justify-content-center">
                        <ButtonToolbar>
                            <Button color="success" size="xs" onClick={() => {this.deleteUser(row.UserId)}}>
                                Yes
                            </Button>
                            <Button color="danger" size="xs" onClick={() => {this.togglePopover(row.UserId)}}>
                                No
                            </Button>
                        </ButtonToolbar>
                    </PopoverBody>
                </Popover>
            </ButtonToolbar>
        )
    }

    renderSizePerPageDropDown = (props) => {
        const limits = [];
        props.sizePerPageList.forEach((limit) => {
            limits.push(<DropdownItem key={limit}
                                      onClick={() => props.changeSizePerPage(limit)}>{limit}</DropdownItem>);
        });

        return (
            <Dropdown isOpen={props.open} toggle={props.toggleDropDown}>
                <DropdownToggle color="default" caret>
                    {props.currSizePerPage}
                </DropdownToggle>
                <DropdownMenu>
                    {limits}
                </DropdownMenu>
            </Dropdown>
        );
    };

    getUsers = () => {
        const {curRoleId, users} = this.props;

        var data = users.filter(function( u ) {
            for (var i = 0; i < u.Roles.length; i++) {
                if (u.Roles[i] == curRoleId) {
                    return true;
                }
            }
            return false;
        });

        return data;
    }

    getUserRoles = (roleIds) => {
        const {roles} = this.props;

        var data = roles.filter(function( r ) {
            for (var i = 0; i < roleIds.length; i++) {
                if (roleIds[i] == r.Id) {
                    return true;
                }
            }
            return false;
        });

        var userRoles = [];
        for (var i = 0; i< data.length; i++) {
            userRoles.push(data[i].Name);
        }
        
        return userRoles.join(", ");
    }

    render() {
        const options = {
            sizePerPage: 10,
            paginationSize: 3,
            sizePerPageDropDown: this.renderSizePerPageDropDown,
        };

        const data = this.getUsers();//this.props.users;//this.getUsers();
        const {roles, curRoleId, isReceiving, isUpdating, isDeleting} = this.props;
        
        const {isAddModal, curUser, selectedRole, userError} = this.state;
        const userRoles = this.getUserRoles(curUser.Roles);

        return (
            <div className="body_wrapper">
                <div className="left_sidebar">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        {roles.map((role, i) => 
                            <a 
                                href="#" 
                                className={curRoleId == role.Id ? "nav-item nav-link active" : "nav-item nav-link"} 
                                key={role.Id}
                                onClick={(e) => this.onClickRole(e, role.Id)}>
                                    {role.Name}
                            </a>
                        )}
                    </div>
                </div>
                <div className="right_sidebar">
                    {
                        isReceiving || isUpdating || isDeleting ? (<Loader size={80}/>) : (<div></div>)
                    }
                    <div className="tab_header">
                        <h1 className="d-flex">
                            <span className="d-block">People</span>
                            <button type="button" className="btn" onClick={()=> this.editUser(0)}>Add New</button>
                        </h1>
                        {/* <form className="search_box">
                            <div className="form-group d-flex">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="zmdi zmdi-search"></i></div>
                                </div>
                                <input type="text" className="form-control" placeholder="Search"/>
                            </div>
                        </form> */}
                    </div>
                    <Widget>
                        <div className="order_list">
                            <BootstrapTable data={data} tableHeaderClass='list_header' bordered={ false } striped={true} version="4" pagination options={options} 
                                            tableContainerClass={`table-striped ${s.bootstrapTable}`} search>
                                <TableHeaderColumn dataField="UserId" className="width-5p"
                                                columnClassName="width-5p">
                                    <span className="fs-sm">ID</span>
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="Username" className="width-15p"
                                                columnClassName="width-15p">
                                    <span className="fs-sm">Name</span>
                                </TableHeaderColumn>
                                {window.innerWidth >= 768 && (
                                    <TableHeaderColumn dataField="Telephone" className="width-15p"
                                    columnClassName="width-15p">
                                        <span className="fs-sm">Telephone</span>
                                    </TableHeaderColumn>
                                )}
                                {window.innerWidth >= 768 && (
                                    <TableHeaderColumn dataField="Email" className="width-10p"
                                    columnClassName="width-10p">
                                        <span className="fs-sm">Email Address</span>
                                    </TableHeaderColumn>
                                )}
                                {window.innerWidth >= 768 && (
                                    <TableHeaderColumn dataField="createdAt" className="width-10p"
                                    columnClassName="width-10p">
                                        <span className="fs-sm">Created At</span>
                                    </TableHeaderColumn>
                                )}
                                <TableHeaderColumn dataField="UserId" isKey={true}  dataFormat={this.apiFormatter} className="width-10p"
                                                columnClassName="width-10p">
                                    <span className="fs-sm">Action</span>
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                        <Drawer anchor="right" open={isAddModal} onClose={this.hideAddModal}>
                            <div className="add_category_model add_expenses receipt_model px-0" 
                                id="add_expenses" tabIndex="-1" role="dialog" aria-labelledby="receipt_modelTitle" aria-modal="true">
                                <div className="modal-dialog modal-dialog-scrollable" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header px-0">
                                            <h2 className="col-10 mx-auto">{curUser.UserId == 0 ? "Add People": "Edit People"}</h2>
                                        </div>
                                        <div className="row alert-container">
                                        {
                                            userError && (
                                                <Alert className="alert-sm" color="danger">
                                                    {userError}
                                                </Alert>
                                            )
                                        }
                                        </div>
                                        <div className="modal-body p-0">
                                            <form>
                                                <div className="col-10 mx-auto form_container">
                                                    <div className="form-group">
                                                        <label>Name</label>
                                                        <input type="text" className="form-control" value={curUser.Username} onChange={this.changeUsername}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Phone Number</label>
                                                        <input type="text" className="form-control" value={curUser.Telephone == null ? "" : curUser.Telephone} onChange={this.changePhone}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Email Address</label>
                                                        <input type="email" className="form-control" value={curUser.Email == null ? "" : curUser.Email} onChange={this.changeEmail}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Pin Number</label>
                                                        <input type="text" className="form-control" value={curUser.Pin == null ? "" : curUser.Pin} onChange={this.changePin}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Role type</label>
                                                        <select className="form-control" value={selectedRole} onChange={this.changeRole}>
                                                        {roles.map((role, i) => 
                                                            <option value={role.Id} key={role.Id}>{role.Name}</option>
                                                        )}
                                                        </select>
                                                    </div>
                                                    <div className="form-group role-button-group">
                                                        <button className="btn Cencel" onClick={(e) => this.deleteUserRole(e)}>Delete</button>
                                                        <button className="btn Cencel" onClick={(e) => this.addUserRole(e)}>Add</button>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Role</label>
                                                        <input type="text" className="form-control" value={userRoles} readOnly/>
                                                    </div>
                                                </div>

                                                <div className="modal-footer">
                                                    <div className="row no-gutters w-100">
                                                        <div className="col-6"> <button className="btn Cencel" onClick={(e) => this.hideAddModal(e)}>Cancel</button></div>
                                                        <div className="col-6"> <button className="btn" onClick={(e) => this.createNewUser(e)}>{curUser.UserId == 0 ? "Add": "Update"}</button></div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Drawer>
                    </Widget>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        users: state.users.data,
        roles: state.users.roles,
        curRoleId: state.users.curRoleId,
        isReceiving: state.users.isReceiving,
        isUpdating: state.users.isUpdating,
        isDeleting: state.users.isDeleting,
        idToDelete: state.users.idToDelete,
        merchant: state.auth.merchant,
    };
}

export default withRouter(connect(mapStateToProps)(Users));


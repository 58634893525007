import {
  RECEIVED_USERS,
  RECEIVED_USER,
  CREATED_USER,
  UPDATED_USER,
  DELETED_USER,
  RECEIVING_USERS,
  RECEIVING_USER,
  UPDATING_USER,
  DELETING_USER,
  FAILURE_USER,
  SET_ROLE_ID
} from '../actions/users';

const defaultState = {
    roles: [],
    data: [],
    curRoleId: 1,
    isReceiving: false,
    isUpdating: false,
    isDeleting: false,
    idToDelete: null,
    error: ""
};

export default function usersReducer(state = defaultState, action) {
    switch (action.type) {
        case RECEIVED_USERS:
            return Object.assign({}, state, {
                data: action.payload.users,
                roles: action.payload.roles,
                isReceiving: false,
            });
        case RECEIVED_USER:
            return Object.assign({}, state, {
                data: [...state.data, action.payload],
                isReceiving: false,
            });
        case CREATED_USER:
            return Object.assign({}, state, {
                data: [...state.data, action.payload],
                isUpdating: false,
            });
        case UPDATED_USER:
            let index = state.data.findIndex(p => p.UserId === action.payload.UserId);
            return Object.assign({}, state, {
                data: state.data.map((p, i) => {
                    if (i === index) {
                        return Object.assign({}, p, action.payload);
                    }
                    return p;
                }),
                isUpdating: false,
            });
        case DELETED_USER:
            let indexToDelete = state.data.findIndex(p => p.UserId === action.payload);
            let data = [...state.data];
            data.splice(indexToDelete, 1);
            return Object.assign({}, state, {
                data: [...data],
                isDeleting: false,
                idToDelete: null
            });
        case RECEIVING_USERS:
        case RECEIVING_USER:
            return Object.assign({}, state, {
                isReceiving: true
            });
        case UPDATING_USER:
            return Object.assign({}, state, {
                isUpdating: true
            });
        case DELETING_USER:
            return Object.assign({}, state, {
                isDeleting: true,
                idToDelete: action.payload
            });
        case FAILURE_USER:
            return Object.assign({}, state, {
                error: action.payload,
                isReceiving: false,
                isUpdating: false,
                isDeleting: false
            });
        case SET_ROLE_ID:
            return Object.assign({}, state, {
                curRoleId: action.payload,
            });
        default:
            return state;
    }
}

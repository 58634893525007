import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';

import {
    BootstrapTable,
    TableHeaderColumn,
} from 'react-bootstrap-table';
import { connect } from 'react-redux';

import { Popover, PopoverBody, PopoverHeader, Alert } from 'reactstrap';

import {
    Button,
    ButtonToolbar,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";

import Loader from '../../../../components/Loader/Loader';
import Widget from '../../../../components/Widget/Widget';

import s from './Pictures.module.scss';

import { loadPictures, createPicture, updatePicture, deletePicture } from '../../../../actions/settings'

class Pictures extends React.Component {
    
    static propTypes = {
        products: PropTypes.array,
        dispatch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        products: []
    };

    state = {
        popovers: {},
        promoAlert: false,
        isAddModal: false,
        picName: "",
        curPicture: null,
        picError: "",
        picFile: null,
        tempPicture: "",
        editError: ""
    };

    constructor() {
        super();

        this.changeName = this.changeName.bind(this);
        this.changePicture = this.changePicture.bind(this);
        this.changeEditPictureName = this.changeEditPictureName.bind(this);
        
        this.apiFormatter = this.apiFormatter.bind(this);
    }

    componentDidMount() {
     //   this.props.dispatch(loadPictures());
    }

    changeName(event) {
        this.setState({ picName: event.target.value, picError: "" });
    }

    changeEditPictureName(event) {
        var curPicture = this.state.curPicture;
        curPicture.Name = event.target.value;
        this.setState({ curPicture: curPicture, editError: "" });

        this.setState({ stationName: event.target.value, stationError: "" });
    }

    changePicture(event) {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            
            this.setState({
              tempPicture: URL.createObjectURL(file),
              picFile: file
            });
        } else {
            this.setState({picFile: null, tempPicture: ""});
        }
    }

    onClickSavePicture = (e) => {
        e.preventDefault();

        var picError = "";
        const {picName, picFile} = this.state;
        
        if (picName == "") {
            picError = "Please input name";
        } else if (picFile == null) {
            picError = "Please choose an image file";
        } 

        if (picError == "") {
            this.props.dispatch(createPicture({
                picFile,
                picName
            }))
        }
        this.setState({picError: picError});
    }

    onClickUpdate = (event) => {
        event.preventDefault();

        const { curPicture } = this.state;

        var editError = "";
        if (curPicture.Name == "") {
            editError = "Please input the picture name";
        } 

        if (editError == "") {
            this.props.dispatch(updatePicture(curPicture));
            this.setState({isAddModal: false});
        } else {
            this.setState({editError: editError});
        }
    }

    editPicture (assetId) {
        const picture = this.getPictureById(assetId);
        this.setState({isAddModal: true, curPicture: picture});
    }

    getPictureById = (id) => {
        const { pictures } = this.props;
        var data = pictures.filter(function( p ) {
            return p.AssetId == id;
        });

        var picture = null;
        if (data.length > 0) {
            picture = {... data[0]};
        } 
        return picture;
    }

    removePicture(id) {
        this.togglePopover(id);
        this.props.dispatch(deletePicture(id));
    }

    hideAddModal = (e) => {
        e.preventDefault();
        this.setState({isAddModal: false});
    }

    togglePopover(id) {
        let newState = {...this.state};
        if (!this.state.popovers[id]) {
            newState.popovers[id] = true;
        } else {
            newState.popovers[id] = !newState.popovers[id];
        }
        this.setState(newState);
    }

    apiFormatter(cell, row) {
        return (
            <ButtonToolbar className="settings-action">
                <button id={'popoverDelete_' + row.AssetId} className="btn">
                    {this.props.isDeleting && this.props.idToDelete === row.AssetId ? <Loader size={14}/> :
                        <div><i className="zmdi zmdi-delete"></i></div>
                    }
                </button>
                <button className="btn" onClick={()=> this.editPicture(row.AssetId)}>
                    <div><i className="zmdi zmdi-edit"></i></div>
                </button>
                
                <Popover className="popover-danger" target={'popoverDelete_' + row.AssetId} placement="top" isOpen={this.state.popovers[row.AssetId]}
                         toggle={()=>{this.togglePopover(row.AssetId)}}
                >
                    <PopoverHeader className="px-5">Are you sure?</PopoverHeader>
                    <PopoverBody className="px-5 d-flex justify-content-center">
                        <ButtonToolbar>
                            <Button color="success" size="xs" onClick={() => {this.removePicture(row.AssetId)}}>
                                Yes
                            </Button>
                            <Button color="danger" size="xs" onClick={() => {this.togglePopover(row.AssetId)}}>
                                No
                            </Button>
                        </ButtonToolbar>
                    </PopoverBody>
                </Popover>
            </ButtonToolbar>
        )
    }

    imageFormatter(cell) {
        if (cell == "" || cell == null ) {
            return (<div></div>);
        } else {
            return (
                <img src={cell} alt="..." className={s.image} title="image"/>
            )
        }
    }

    renderSizePerPageDropDown = (props) => {
        const limits = [];
        props.sizePerPageList.forEach((limit) => {
            limits.push(<DropdownItem key={limit}
                                      onClick={() => props.changeSizePerPage(limit)}>{limit}</DropdownItem>);
        });

        return (
            <Dropdown isOpen={props.open} toggle={props.toggleDropDown}>
                <DropdownToggle color="default" caret>
                    {props.currSizePerPage}
                </DropdownToggle>
                <DropdownMenu>
                    {limits}
                </DropdownMenu>
            </Dropdown>
        );
    };
    
    render() {
        const options = {
            sizePerPage: 10,
            paginationSize: 3,
            sizePerPageDropDown: this.renderSizePerPageDropDown,
        };

        const {curPicture, picError, tempPicture, picName, editError, isAddModal} = this.state;
        const { pictures } = this.props;
        const data = pictures;

        return (
            <div className="bg-second p-4">
                <div className="form-group">
                    <div className={`d-flex align-items-center px-2 mb-3 text-white py-2 ${s.sub_header}`}>
                        <h4>
                            Default Images Upload - <small > Images you would like available for display </small>
                        </h4>
                    </div>  
                </div> 
                <form>
                    <div className={`row ${s.imageContainer}`}>
                        <div className="upload-box mt-1 mb-4 mx-auto">
                            <label htmlFor="img" className="img m-0 active">
                                <i className="zmdi zmdi-image-alt"></i>
                                <input id="img" type="file" onChange={this.changePicture} accept="image/png, image/jpeg"/>
                                <span>Upload image</span>
                            </label>
                        </div>
                        {
                            (tempPicture == "" || tempPicture == null) ? (
                                <div></div>
                            ) : (
                                <div className="col-md-6">
                                    {
                                        tempPicture != "" ? (
                                            <img src={tempPicture} alt="..." className={s.image} title="image"/>
                                        ) : (
                                            <img src={curPicture.Thumbnail} alt="..." className={s.image} title="image"/>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Image Name</label>
                                <input type="text" className="form-control" placeholder="" required="" value={picName} onChange={this.changeName}/>
                            </div>
                        </div>
                    </div>
                    <div className="row alert-container">
                    {
                        picError && (
                            <Alert className="alert-sm" color="danger">
                                {picError}
                            </Alert>
                        )
                    }
                    </div>
                    <div className={`form-group col-md-12 ${s.btnContainer}`}>
                        <a href="#" className="btn btn-save btn-sm mt-2" onClick={(e) => this.onClickSavePicture(e)} >Save Image</a>
                    </div>
                </form>
                <Widget>
                    <div className="order_list">
                        <BootstrapTable data={data} tableHeaderClass='list_header' bordered={ false } striped={true} version="4" pagination options={options} 
                                        tableContainerClass={`table-striped ${s.bootstrapTable}`}>
                            <TableHeaderColumn dataField="Thumbnail" dataFormat={this.imageFormatter} className="width-10p"
                                            columnClassName="width-10p">
                                <span className="fs-sm">Image</span>
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="Name" className="width-15p"
                                            columnClassName="width-15p">
                                <span className="fs-sm">Image Name</span>
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="AssetId" isKey={true}  dataFormat={this.apiFormatter} className="width-10p"
                                            columnClassName="width-10p">
                                <span className="fs-sm"></span>
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </Widget>
                <Drawer anchor="right" open={isAddModal} onClose={this.hideAddModal}>
                    <div className="add_category_model add_expenses receipt_model px-0" 
                        id="add_expenses" tabIndex="-1" role="dialog" aria-labelledby="receipt_modelTitle" aria-modal="true">
                        <div className="modal-dialog modal-dialog-scrollable" role="document">
                            <div className="modal-content">
                                <div className="modal-header px-0">
                                    <h2 className="col-10 mx-auto">Edit Image Name</h2>
                                </div>
                                <div className="row alert-container">
                                {
                                    editError && (
                                        <Alert className="alert-sm" color="danger">
                                            {editError}
                                        </Alert>
                                    )
                                }
                                </div>
                                <div className="modal-body p-0">
                                    <form>
                                        <div className="col-10 mx-auto form_container">
                                            {
                                                curPicture == null ? (
                                                    <div></div>
                                                ) : (
                                                    <div className="form-group">
                                                        <label>Image Name</label>
                                                        <input type="text" className="form-control" value={curPicture.Name} onChange={this.changeEditPictureName}/>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className="modal-footer">
                                            <div className="row no-gutters w-100">
                                                <div className="col-6"> <button className="btn Cencel" onClick={(e) => this.hideAddModal(e)}>Cancel</button></div>
                                                <div className="col-6"> <button className="btn" onClick={(e) => this.onClickUpdate(e)}>Update</button></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Drawer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pictures: state.settings.pictures,
        isReceiving: state.settings.isReceiving,
        isUpdating: state.settings.isUpdating,
        isDeleting: state.settings.isDeleting,
        idToDelete: state.settings.idToDelete,
    };
}

export default withRouter(connect(mapStateToProps)(Pictures));


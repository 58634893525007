import React, { useState, useEffect } from "react";

const getTimePeriods = () => {
    const periods = [];
    for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 60; j+= 10) {
            let period = i < 10 ? `0${i}` : i;
            period = j < 10 ? `${period}:0${j}` : `${period}:${j}`;
            periods.push(period);
        }
    }

    return periods;
};

const TimePicker = ({label, value, onChange}) => {
  
  const periods = getTimePeriods();

  return (
    <div className="time-picker form-group">
        <label>{label}</label>
        <select value={value === null ? '' : value} onChange={onChange}>
            <option value="">No Defined</option>
            {periods.map((period, index) => (
                <option key={index} value={period}>{period}</option>
            ))}
        </select>
    </div>
  );
};

export default TimePicker;

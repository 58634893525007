/* eslint-disable import/prefer-default-export */

export const CHANGE_ACTIVE_NAVBAR_ITEM = 'CHANGE_ACTIVE_NAVBAR_ITEM';


export function changeActiveNavbarItem(activeItem) {
  return {
    type: CHANGE_ACTIVE_NAVBAR_ITEM,
    activeItem,
  };
}


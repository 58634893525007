import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import config from '../../config';
import { connect } from 'react-redux';
import { Container, Alert, Button } from 'reactstrap';
import { loginUser, receiveToken, doInit } from '../../actions/auth';
import jwt from "jsonwebtoken";

import logo_img from '../../images/logo.png';

class Login extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    };

    static isAuthenticated() {
      /*const token = localStorage.getItem('token');
      if (!config.isBackend && token) return true;
      console.log("isAuthenticated");
      if (!token) return;
      const date = new Date().getTime() / 1000;
      const data = jwt.decode(token);
      if (!data) return;
      return date < data.exp;*/

      const user = localStorage.getItem('user');
      if (!user) return;
      const date = new Date().getTime() / 1000;
      const data = JSON.parse(user);
      if (!data) return;

      return date < data.expires_in + data.issued;
    }

    constructor(props) {
        super(props);

        this.state = {
          username: '',
          password: ''
        };

        this.doLogin = this.doLogin.bind(this);
        this.changeUsername = this.changeUsername.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    changeUsername(event) {
        this.setState({ username: event.target.value });
    }

    changePassword(event) {
        this.setState({ password: event.target.value });
    }

    doLogin(e) {
        e.preventDefault();
        this.props.dispatch(loginUser({ username: this.state.username, password: this.state.password, grant_type: 'password' }));
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const token = params.get('token');
        console.log("login did mount token", token);
        if (token) {
            this.props.dispatch(receiveToken(token));
            this.props.dispatch(doInit());
        }
    }

    render() {
        return (
            <div id="page_sign_in">
                <div className="container mx-auto my-auto px-0">
                  <form onSubmit={this.doLogin}>
                    <div className="col-10 mx-auto pt-5 px-4 pt-1">
                      <div className="logo_box mx-auto text-center">
                          <img src={logo_img} className="img-fluid"/>
                      </div>
                      <h2 className="text-white">Login to your Account</h2>
                      {
                          this.props.errorMessage && (
                              <Alert className="alert-sm" color="danger">
                                  {this.props.errorMessage}
                              </Alert>
                          )
                      }
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Username</label>
                        <input type="text" className="form-control" value={this.state.username} onChange={this.changeUsername}  placeholder="Enter Username" required name="username"/>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input type="password" className="form-control" value={this.state.password} onChange={this.changePassword} placeholder="Enter password" required name="password"/>
                      </div>
                    </div>
                    <button type="submit" className="btn">{this.props.isFetching ? 'Loading...' : 'Login'}</button>
                  </form>    
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
        errorMessage: state.auth.errorMessage,
    };
}

export default withRouter(connect(mapStateToProps)(Login));


import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { push } from 'connected-react-router';

import { logoutUser } from '../../actions/auth';

import Products from '../../pages/products/Products';
import Settings from '../../pages/settings/Settings';
import Users from '../../pages/users/Users';

import logo_img from '../../images/logo.png';
import profile_img from '../../images/profile.png';

class Layout extends React.Component {
  static propTypes = {
    activeItem: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeItem: 'products'
  };

  state = {
    isNavMenu: false
  };

  constructor(props) {
    super(props);
    // this.gotoProducts = this.gotoProducts.bind(this);
    // this.gotoUsers = this.gotoUsers.bind(this);
    this.doLogout = this.doLogout.bind(this);
  }

  toggleNavMenu() {
    const { isNavMenu } = this.state;

    this.setState({ isNavMenu: !isNavMenu });
  }

  
  // gotoProducts(e) {
  //   console.log("goto products");
  //   e.preventDefault();
  //   this.props.dispatch(push('/admin/products'));
  // }

  // gotoUsers(e) {
  //   console.log("goto users");
  //   e.preventDefault();
  //   this.props.dispatch(push('/admin/users'));
  // }

  doLogout(e) {
    console.log("goto users");
    e.preventDefault();
    this.props.dispatch(logoutUser());
    window.location.href="/";
  }

  render() {
    
    const { pathname } = this.props.location;
    const { isNavMenu } = this.state;

    return (
      <div>
        <header className="container-fluid ">
          <nav className="navbar navbar-expand-xl navbar-light align-items-center">
            <div className="nav-item">
                <a className="navbar-brand nav-link px-2" href="#">
                    <img src={logo_img} className="img-fluid"/>
                </a>
            </div>

            <button className="navbar-toggler" type="button" data-toggle="collapse" aria-expanded="false" aria-label="Toggle navigation" onClick={() => this.toggleNavMenu()}>
                <i className="zmdi zmdi-menu"></i>
            </button>
            <div className={isNavMenu ? "collapse navbar-collapse show": "collapse navbar-collapse"}>
                <ul className="navbar-nav mr-0 ml-auto d-flex align-items-center">
                    <li className={pathname.startsWith('/admin/products') ? "nav-item active" : "nav-item"}>
                      <Link className="nav-link" to="/admin/products"><i className="zmdi zmdi-cutlery"></i> Product List</Link>
                    </li>
                    <li className={pathname.startsWith('/admin/settings') ? "nav-item active" : "nav-item"}>
                      <Link className="nav-link" to="/admin/settings"><i className="zmdi zmdi-settings"></i> Settings</Link>
                    </li>
                    <li className={pathname.startsWith('/admin/users') ? "nav-item active" : "nav-item"}>
                      <Link className="nav-link" to="/admin/users"><i className="zmdi zmdi-accounts-alt"></i> Users</Link>
                      {/* <a className="nav-link" href="#" onClick={this.gotoUsers}><i className="zmdi zmdi-accounts-alt"></i> Users</a> */}
                    </li>
                    <li className="nav-item logout">
                        <a href="#" onClick={this.doLogout}>
                          <i className="zmdi zmdi-sign-in"></i> Log Out
                            {/* <img src={profile_img} className="crop_img"/> */}
                        </a>
                    </li>
                </ul>
            </div>
          </nav>
        </header>
        <main>
          <TransitionGroup>
            <CSSTransition
              key={this.props.location.key}
              classNames="fade"
              timeout={200}
            >
              <Switch>
                <Route path="/admin" exact render={() => <Redirect to="/admin/products" />} />
                <Route path="/admin/products" component={Products} />
                <Route path="/admin/settings" component={Settings} />
                <Route path="/admin/users" component={Users} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </main>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    activeItem: store.navigation.activeItem
  };
}

export default withRouter(connect(mapStateToProps)(Layout));

import {
  RECEIVED_CATEGORIES,
  RECEIVED_PRODUCTS,
  RECEIVED_PRODUCT,
  CREATED_PRODUCT,
  CREATED_VARIATION,
  UPDATED_VARIATION,
  CREATED_OPTION,
  UPDATED_OPTION,
  DELETED_OPTION,
  UPDATED_PRODUCT,
  DELETED_PRODUCT,
  DELETED_VARIATION,
  RECEIVING_PRODUCTS,
  RECEIVING_PRODUCT,
  UPDATING_PRODUCT,
  DELETING_PRODUCT,
  RECEIVED_IMAGES,
  SET_CATEGORY_ID,
  SET_CURRENT_PRODUCT,
  FAILURE_PRODUCT,
  CREATED_CATEGORY,
  UPDATED_CATEGORY,
  DELETED_CATEGORY,
  UPDATING_CATEGORY,
  DELETING_CATEGORY,
  FAILURE_CATEOGRY
} from '../actions/products';

const defaultState = {
    data: [],
    categories: [],
    images: [],
    curCategoryId: 0,
    curProductId: 0,
    isReceiving: false,
    isUpdating: false,
    isDeleting: false,
    idToDelete: null,
    error: ""
};

export default function productsReducer(state = defaultState, action) {
    switch (action.type) {
        case RECEIVED_CATEGORIES:
            return Object.assign({}, state, {
                categories: action.payload.categories,
                curCategoryId: 0,
                isReceiving: false,
            });
        case RECEIVED_PRODUCTS:
            return Object.assign({}, state, {
                data: action.payload.products,
                categories: action.payload.categories,
                curCategoryId: 0,
                isReceiving: false,
            });
        case RECEIVED_PRODUCT:
            return Object.assign({}, state, {
                data: [...state.data, action.payload],
                isReceiving: false,
            });
        case CREATED_PRODUCT:
            return Object.assign({}, state, {
                data: [...state.data, action.payload],
                curProductId: action.payload.ProductId,
                isUpdating: false,
                error: "",
            });
        case CREATED_VARIATION:
        case UPDATED_VARIATION:
            // var products = state.data.filter(p => p.ProductId === action.payload.ProductId);
            // var product = products[0];
            // product.Variations = [... product.Variations, action.payload.variation];

            // let indexForVariation = state.data.findIndex(p => p.ProductId === action.payload.ProductId);
            return Object.assign({}, state, {
                // data: state.data.map((p, i) => {
                //     if (i === indexForVariation) {
                //         return Object.assign({}, p, product);
                //     }
                //     return p;
                // }),
                isUpdating: false,
            });
        case CREATED_OPTION:
        case UPDATED_OPTION:
            // var products = state.data.filter(p => p.ProductId === action.payload.ProductId);
            // var product = products[0];
            // product.Options = [... product.Options, action.payload.option];

            // let indexForOption = state.data.findIndex(p => p.ProductId === action.payload.ProductId);
            return Object.assign({}, state, {
                // data: state.data.map((p, i) => {
                //     if (i === indexForOption) {
                //         return Object.assign({}, p, product);
                //     }
                //     return p;
                // }),
                isUpdating: false,
            });
        case CREATED_CATEGORY:
            return Object.assign({}, state, {
                categories: [...state.categories, action.payload],
                isUpdating: false,
            });
        case UPDATED_PRODUCT:
            let index = state.data.findIndex(p => p.ProductId === action.payload.ProductId);
            return Object.assign({}, state, {
                data: state.data.map((p, i) => {
                    if (i === index) {
                        return Object.assign({}, p, action.payload);
                    }
                    return p;
                }),
                isUpdating: false,
            });
        case UPDATED_CATEGORY:
            let indexForCatogory = state.categories.findIndex(c => c.CategoryId === action.payload.CategoryId);
            return Object.assign({}, state, {
                categories: state.categories.map((c, i) => {
                    if (i === indexForCatogory) {
                        return Object.assign({}, c, action.payload);
                    }
                    return c;
                }),
                isUpdating: false,
            });
        case DELETED_PRODUCT:
            let indexToDelete = state.data.findIndex(p => p.ProductId === action.payload);
            let data = [...state.data];
            data.splice(indexToDelete, 1);
            return Object.assign({}, state, {
                data: [...data],
                isDeleting: false,
                idToDelete: null
            });
        case DELETED_VARIATION:
            let prod_data = [...state.data];

            var prods = prod_data.filter(function( p ) {
                return p.ProductId == action.payload.productId;
            });
            var prod = prods[0];
            
            let indexToDeleteVariation = prod.Variations.findIndex(v => v.VariationId === action.payload.variationId);
            prod.Variations.splice(indexToDeleteVariation, 1);

            return Object.assign({}, state, {
                data: [...prod_data],
                isDeleting: false,
                idToDelete: null
            });
        case DELETED_OPTION:
            let option_prod_data = [...state.data];

            var prods = option_prod_data.filter(function( p ) {
                return p.ProductId == action.payload.productId;
            });
            var prod = prods[0];
            
            let indexToDeleteOption = prod.Options.findIndex(o => o.OptionId === action.payload.optionId);
            prod.Options.splice(indexToDeleteOption, 1);

            return Object.assign({}, state, {
                data: [...option_prod_data],
                isDeleting: false,
                idToDelete: null
            });
        case DELETED_CATEGORY:
            let indexToCatDelete = state.categories.findIndex(c => c.CategoryId === action.payload);
            let categories = [...state.categories];
            categories.splice(indexToCatDelete, 1);
            return Object.assign({}, state, {
                categories: [...categories],
                isDeleting: false,
                idToDelete: null
            });
        case RECEIVING_PRODUCTS:
        case RECEIVING_PRODUCT:
            return Object.assign({}, state, {
                isReceiving: true
            });
        case UPDATING_PRODUCT:
            return Object.assign({}, state, {
                isUpdating: true,
                error: ""
            });
        case UPDATING_CATEGORY:
            return Object.assign({}, state, {
                isUpdating: true
            });
        case DELETING_PRODUCT:
        case DELETING_CATEGORY:
            return Object.assign({}, state, {
                isDeleting: true,
                idToDelete: action.payload.id
            });
        case RECEIVED_IMAGES:
            return Object.assign({}, state, {
                images: action.payload,
            });
        case SET_CATEGORY_ID:
            return Object.assign({}, state, {
                curCategoryId: action.payload,
            });
        case SET_CURRENT_PRODUCT:
            return Object.assign({}, state, {
                curProductId: action.payload,
                isUpdating: false
            });
        case FAILURE_PRODUCT:
        case FAILURE_CATEOGRY:
            return Object.assign({}, state, {
                error: action.payload,
                isReceiving: false,
                isUpdating: false,
                isDeleting: false
            });
        default:
            return state;
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Alert } from 'reactstrap';
import DefaultProduct from './DefaultProduct';

import { loadStoreDetails, updateStoreDetails, updateStorePicture } from '../../../actions/settings'
import { mockStoreDetails, mockStoreTypes } from '../../../model/mockStoreDetails';

import s from './StoreSettings.module.scss';
import TimePicker from '../../../components/TimePicker';

class StoreSettings extends React.Component {
    
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    };

    static defaultProps = {
    };

    state = {
        popovers: {},
        promoAlert: false,
        isAddModal: false,
        curStoreDetails: mockStoreDetails,
        picFile: null,
        tempPicture: "",
        storeError: "",
        pictureError: ""
    };

    constructor() {
        super();
        
        this.changeStoreName = this.changeStoreName.bind(this);
        this.changeStoreType = this.changeStoreType.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changeTelephone = this.changeTelephone.bind(this);
        this.changeUseClientAddress = this.changeUseClientAddress.bind(this);
        this.changeStreet = this.changeStreet.bind(this);
        this.changeCity = this.changeCity.bind(this);
        this.changeState = this.changeState.bind(this);
        this.changeZipcode = this.changeZipcode.bind(this);
        this.changeDescription = this.changeDescription.bind(this);
        this.changeReceiptText = this.changeReceiptText.bind(this);
        //this.changePicture = this.changePicture.bind(this);
        this.changeStoreImage = this.changeStoreImage.bind(this);
        this.onChangeStartTime = this.onChangeStartTime.bind(this);
        this.onChangeEndTime = this.onChangeEndTime.bind(this);
    }

    componentDidMount() {
        console.log("setting mouted")
        this.props.dispatch(loadStoreDetails());
    }

    componentDidUpdate(prevProps) {
        if (this.props.storeDetails.StoreName !== prevProps.storeDetails.StoreName) {
            this.setState({curStoreDetails: this.props.storeDetails});
        } else if (this.props.storeDetails.StoreType !== prevProps.storeDetails.StoreType) {
            this.setState({curStoreDetails: this.props.storeDetails});
        } else if (this.props.storeDetails.Telephone !== prevProps.storeDetails.Telephone) {
            this.setState({curStoreDetails: this.props.storeDetails});
        } else if (this.props.storeDetails.UseClientAddress !== prevProps.storeDetails.UseClientAddress) {
            this.setState({curStoreDetails: this.props.storeDetails});
        } else if (this.props.storeDetails.Street !== prevProps.storeDetails.Street) {
            this.setState({curStoreDetails: this.props.storeDetails});
        } else if (this.props.storeDetails.City !== prevProps.storeDetails.City) {
            this.setState({curStoreDetails: this.props.storeDetails});
        } else if (this.props.storeDetails.State !== prevProps.storeDetails.State) {
            this.setState({curStoreDetails: this.props.storeDetails});
        } else if (this.props.storeDetails.Zipcode !== prevProps.storeDetails.Zipcode) {
            this.setState({curStoreDetails: this.props.storeDetails});
        } else if (this.props.storeDetails.Description !== prevProps.storeDetails.Description) {
            this.setState({curStoreDetails: this.props.storeDetails});
        } else if (this.props.storeDetails.ReceiptText !== prevProps.storeDetails.ReceiptText) {
            this.setState({curStoreDetails: this.props.storeDetails});
        } else if (this.props.isReceiving !== prevProps.isReceiving && this.props.isReceiving == false) {
            this.setState({curStoreDetails: this.props.storeDetails});
        }
    }

    changeStoreName(event) {
        var curStoreDetails = this.state.curStoreDetails;
        curStoreDetails.StoreName = event.target.value;
        this.setState({ curStoreDetails: curStoreDetails, storeError: "" });
    }

    changeStoreType(event) {
        var curStoreDetails = this.state.curStoreDetails;
        curStoreDetails.StoreType = event.target.value;
        this.setState({ curStoreDetails: curStoreDetails, storeError: "" });
    }

    changeEmail(event) {
        var curStoreDetails = this.state.curStoreDetails;
        curStoreDetails.Email = event.target.value;
        this.setState({ curStoreDetails: curStoreDetails, storeError: "" });
    }

    changeTelephone(event) {
        var curStoreDetails = this.state.curStoreDetails;
        curStoreDetails.Telephone = event.target.value;
        this.setState({ curStoreDetails: curStoreDetails, storeError: "" });
    }

    changeUseClientAddress(event) {
        var curStoreDetails = this.state.curStoreDetails;
        curStoreDetails.UseClientAddress = event.target.checked;
        this.setState({ curStoreDetails: curStoreDetails, storeError: "" });
    }

    changeStreet(event) {
        var curStoreDetails = this.state.curStoreDetails;
        curStoreDetails.Street = event.target.value;
        this.setState({ curStoreDetails: curStoreDetails, storeError: "" });
    }

    changeCity(event) {
        var curStoreDetails = this.state.curStoreDetails;
        curStoreDetails.City = event.target.value;
        this.setState({ curStoreDetails: curStoreDetails, storeError: "" });
    }

    changeState(event) {
        var curStoreDetails = this.state.curStoreDetails;
        curStoreDetails.State = event.target.value;
        this.setState({ curStoreDetails: curStoreDetails, storeError: "" });
    }
    
    changeZipcode(event) {
        var curStoreDetails = this.state.curStoreDetails;
        curStoreDetails.Zipcode = event.target.value;
        this.setState({ curStoreDetails: curStoreDetails, storeError: "" });
    }

    changeDescription(event) {
        var curStoreDetails = this.state.curStoreDetails;
        curStoreDetails.Description = event.target.value;
        this.setState({ curStoreDetails: curStoreDetails, storeError: "" });
    }

    changeReceiptText(event) {
        var curStoreDetails = this.state.curStoreDetails;
        curStoreDetails.ReceiptText = event.target.value;
        this.setState({ curStoreDetails: curStoreDetails, storeError: "" });
    }

    changeStoreImage(event) {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            
            this.setState({
              tempPicture: URL.createObjectURL(file),
              picFile: file,
              pictureError: ""
            });
        } else {
            this.setState({picFile: null, tempPicture: "", pictureError: ""});
        }
    }

    onChangeStartTime (event) {
        var curStoreDetails = this.state.curStoreDetails;
        curStoreDetails.AutoStartWP = event.target.value;
        this.setState({ curStoreDetails: curStoreDetails, storeError: "" });
    }

    onChangeEndTime (event) {
        var curStoreDetails = this.state.curStoreDetails;
        curStoreDetails.AutoCloseWP = event.target.value;
        this.setState({ curStoreDetails: curStoreDetails, storeError: "" });
    }

    // changeStoreImage(event) {
    //     var curStoreDetails = this.state.curStoreDetails;
    //     curStoreDetails.StoreImage = event.target.value;
    //     this.setState({ curStoreDetails: curStoreDetails });
    // }

    onClickSaveStoreDetails = (e) => {
        e.preventDefault();

        var storeError = "";
        const {curStoreDetails} = this.state;

        if (curStoreDetails.StoreName == "") {
            storeError = "Please input store name";
        } else if (curStoreDetails.Email == "") {
            storeError = "Please input email";
        } else if (curStoreDetails.Telephone == "") {
            storeError = "Please input phone number";
        } else if (curStoreDetails.Street == "") {
            storeError = "Please input street";
        } else if (curStoreDetails.City == "") {
            storeError = "Please input street";
        } else if (curStoreDetails.Zipcode == "") {
            storeError = "Please input zip code";
        }

        if (storeError == "") {
            this.props.dispatch(updateStoreDetails(curStoreDetails))
        }
        this.setState({storeError: storeError});
    }

    onClickSavePicture = (e) => {
        e.preventDefault();

        var pictureError = "";
        const {picFile} = this.state;
        
        if (picFile == null) {
            pictureError = "Please choose an image";
        } 

        if (pictureError == "") {
            this.props.dispatch(updateStorePicture(picFile))
        }
        this.setState({pictureError: pictureError});
    }

    

    render() {

        const { curStoreDetails, tempPicture, storeError, pictureError } = this.state;
        const { states } = this.props;

        return (
            <div className="row no-gutters store-settings">
                <div className="col">
                    <div className="bg-second p-4 ">
                        <form>
                            <div className={`${s.header}`}>
                                <h3 className="mt-0 mb-5 text-white">Store Details</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <TimePicker label="Word Period Start Time" value={curStoreDetails.AutoStartWP} onChange={this.onChangeStartTime} />
                                    </div>
                                    <div className="col-md-6">
                                        <TimePicker label="Word Period End Time" value={curStoreDetails.AutoCloseWP} onChange={this.onChangeEndTime} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Store Name</label>
                                        <input type="text" className="form-control" placeholder="" required="" value={curStoreDetails.StoreName} onChange={this.changeStoreName}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Store Type</label>
                                        <select className={`form-control ${s.select}`} value={curStoreDetails.StoreType} onChange={this.changeStoreType}>
                                        {mockStoreTypes.map((storeType, i) => 
                                            <option value={storeType.Id} key={storeType.Id}>{storeType.Name}</option>
                                        )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="text" className="form-control" placeholder="info@rebelity.com" required="" value={curStoreDetails.Email} onChange={this.changeEmail}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Phone No.</label>
                                        <input type="text" className="form-control" placeholder="" required="" value={curStoreDetails.Telephone} onChange={this.changeTelephone}/>
                                    </div>
                                </div>
                            </div>
                            <div className={`d-flex align-items-center px-4 mb-3 text-white py-2 mt-4 ${s.address}`}>
                                <b>Address </b> <i className={`${s.description}`}> ( Use Account default) </i>
                                <label className="switch ml-auto my-1">
                                    <input type="checkbox" checked={curStoreDetails.UseClientAddress} onChange={this.changeUseClientAddress}/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label>Street</label>
                                        <input type="text" className="form-control" placeholder="1234 Lets Party Dr" required="" value={curStoreDetails.Street} onChange={this.changeStreet}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>City</label>
                                        <input type="text" className="form-control" placeholder="Atlanta" required="" value={curStoreDetails.City} onChange={this.changeCity}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>State</label>
                                        <select className={`form-control ${s.select}`} value={curStoreDetails.State} onChange={this.changeState}>
                                        {states.map((st, i) => 
                                            <option value={st.value} key={st.value}>{st.text}</option>
                                        )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Zip</label>
                                        <input type="text" className="form-control" placeholder="30316" required="" value={curStoreDetails.Zipcode} onChange={this.changeZipcode}/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Store Description</label>
                                <textarea className={`form-control mb-2 ${s.text_area}`} placeholder="Please enter a brief description of your store" value={curStoreDetails.Description == null ? "" : curStoreDetails.Description} onChange={this.changeDescription}></textarea>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Reciept Footer text  <i className={`${s.description}`}> ( This text will appear at the bottom of printed reciepts) </i></label>
                                <textarea className={`form-control mb-2 ${s.text_area}`} placeholder="Thank you for your Patronage!" value={curStoreDetails.ReceiptText == null ? "" : curStoreDetails.ReceiptText} onChange={this.changeReceiptText}></textarea>
                            </div>
                            <div className="row alert-container">
                            {
                                storeError && (
                                    <Alert className="alert-sm" color="danger">
                                        {storeError}
                                    </Alert>
                                )
                            }
                            </div>
                            <button type="submit" className="btn pt-2 btn-block" onClick={(e) => this.onClickSaveStoreDetails(e)}>Save Store Details</button>
                        </form>
                    </div>
                </div>
                <div  className={`col-md-4 ${s.image_container}`}>
                    <div>
                        <h3 className="mt-0 text-white">Store Image </h3>
                        <p><i className={`${s.description}`}> (*Defaults to Client Account image if not specified) </i></p>
                        <form>
                            <div className="">
                                <div className="upload text-center my-4">
                                    <div className="upload-box mt-5 mb-4 mx-auto">
                                        <label htmlFor="img" className="img m-0 active">
                                            <i className="zmdi zmdi-image-alt"></i>
                                            <input id="img" type="file" onChange={this.changeStoreImage}/>
                                            <span>Upload Image</span>
                                        </label>
                                    </div>
                                </div>
                                {
                                    (tempPicture == "" || tempPicture == null) && (curStoreDetails.StoreImage == "" || curStoreDetails.StoreImage == null) ? (
                                        <div></div>
                                    ) : (
                                        <div className="upload text-center my-4">
                                            {
                                                tempPicture != "" ? (
                                                    <img src={tempPicture} alt="..." className={s.image} title="image"/>
                                                ) : (
                                                    <img src={curStoreDetails.StoreImage} alt="..." className={s.image} title="image"/>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </form>
                    </div>
                    <div className="row alert-container">
                    {
                        pictureError && (
                            <Alert className="alert-sm" color="danger">
                                {pictureError}
                            </Alert>
                        )
                    }
                    </div>
                    <button type="submit" className="btn pt-2 btn-block" onClick={(e) => this.onClickSavePicture(e)}>Save Store Image</button>

                    <DefaultProduct />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        merchant: state.auth.merchant,
        storeDetails: state.settings.storeDetails,
        states: state.settings.states,
        isReceiving: state.settings.isReceiving,
        isUpdating: state.settings.isUpdating,
        isDeleting: state.settings.isDeleting,
        idToDelete: state.settings.idToDelete,
    };
}

export default withRouter(connect(mapStateToProps)(StoreSettings));


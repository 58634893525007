export const mockProduct = {
  ProductId: 0,
  CategoryId: 2,
  Name: "",
  Description: "",
  SKU: "",
  Barcode: "",
  Price: 0,
  FeeOption: 1,
  TaxRate: 0,
  Picture: null,
  PictureFile: null,
  Inventory: 0,
  Variations: [],
  Options:[]
}

export const mockVariation = {
  VariationId: 0,
  Name: "",
  Cost: 0
}

export const mockOption = {
  OptionId: 0,
  Name: "",
  Cost: 0
}

export const mockFeeOptions = [
  {
    Id: 1,
    Name: "Fees Paid by You"
  },
  {
    Id: 2,
    Name: "Split Fees"
  },
  {
    Id: 3,
    Name: "Fees Paid By Purchaser"
  }
]